import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ak, url } from "../pages/HomePage";

function AdminList(props) {
  const navigate = useNavigate();

  const [admins, setAdmins] = useState([
    {
      username: "KalaMikko",
      email: "mikko.kalastaja@gumail.cum",
      phone: "0406666969",
      privilege: 0,
      password: "KuluttajaMikko",
    },
  ]);
  useEffect(() => {
    fetch(url + "/admin/admin/list" + ak, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const tmpAdminList = [];
        for (const key in data) {
          const admin = {
            id: key,
            ...data[key],
          };

          tmpAdminList.push(admin);
        }
        setAdmins(tmpAdminList);
      });
  }, []);

  return (
    <div className="w-full flex justify-center">
      <table className="w-full h-full card border-0">
        <thead>
          <tr className="text-left border-b-2">
            <th className="text-black p-2">Käyttäjänimi</th>
            <th className="text-black p-2">Sähköposti</th>
            <th colSpan={2} className="text-black text-center">
              Hallinnoi
            </th>
          </tr>
        </thead>
        <tbody>
          {admins.map((i) => {
            return (
              <tr className="border-b-2">
                <td className="py-5 border-black text-black p-2">
                  {i.username}
                </td>
                <td className="py-5 border-black text-black p-2">{i.email}</td>
                <td
                  className="py-5 border-black text-center text-black cursor-pointer"
                  onClick={() =>
                    navigate("/admin/hallinnointi", {
                      state: {
                        id: i.id,
                        username: i.username,
                        fullname: i.fullname,
                        gender: i.gender,
                        email: i.email,
                        phone: i.phone,
                        privilege: i.privilege,
                        postNumber: i.postNumber,
                        cities: i.cities,
                        drivers_licence: i.drivers_licence,
                        car: i.car,
                        categories: i.categories,
                        current_gigs: i.current_gigs,
                        previous_gigs: i.previous_gigs,
                        languages: i.languages,
                        account_created: i.account_created,
                        only_necessary_msgs: i.only_necessary_msgs,
                      },
                    })
                  }
                >
                  Muokkaa
                </td>
                <td
                  className="py-5 border-black text-center text-black"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Haluatko varmasti poistaa käyttäjän ${i.username}`
                      )
                    ) {
                      fetch(url + `/admin/admin/delete/${i.id}` + ak, {
                        method: "DELETE",
                        credentials: "include",
                      }).then(() => {
                        window.location = "/adminit";
                      });
                    }
                  }}
                >
                  Poista
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AdminList;
