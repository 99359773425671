import React from "react";
import { useLocation } from "react-router-dom";
import arrow from "../assets/arrow.png";

function ReportPage() {
  const location = useLocation();
  console.log(location.state);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString("fi-FI");
    const formattedTime = date.toLocaleTimeString("fi-FI").slice(0, -3);

    return `${formattedDate} kello ${formattedTime}`;
  }

  return (
    <div className="w-full h-[90%] flex flex-col items-center justify-center relative">
      <img
        src={arrow}
        alt="takaisin"
        className="absolute h-6 px-8 mb-4 cursor-pointer top-16 left-10"
        onClick={() => window.history.back()}
      ></img>
      <div className="w-3/4 h-auto card">
        <h1 className="text-2xl font-bold">Raportti</h1>
        <br />
        <div className="flex">
          <b>Otsikko: </b>
          <p className="pl-2">{location.state.title}</p>
        </div>
        <div className="flex">
          <b>Asiakas: </b>
          <p className="pl-2">{location.state.customer}</p>
        </div>
        <div className="flex">
          <b>Alkoi: </b>
          <p className="pl-2">{formatDate(location.state.start)}</p>
        </div>
        <div className="flex">
          <b>Loppui: </b>
          <p className="pl-2">{formatDate(location.state.end)}</p>
        </div>
        <b>Työntekijän raportti: </b>
        <p
          dangerouslySetInnerHTML={{
            __html: location.state.report.replace(/-newline-/g, "<br/>"),
          }}
        />
      </div>
    </div>
  );
}

export default ReportPage;
