import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import Geocode from "react-geocode";
import arrow from "../assets/arrow.png";
import { url, ak } from "../pages/HomePage";
import AutoCompleteInput from "../components/AutoCompleteInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const languages = [
  { label: "Abhaasi", value: "ab" },
  { label: "Afar", value: "aa" },
  { label: "Afrikaans", value: "af" },
  { label: "Akan", value: "ak" },
  { label: "Albania", value: "sq" },
  { label: "Amhara", value: "am" },
  { label: "Arabia", value: "ar" },
  { label: "Aragonialainen", value: "an" },
  { label: "Armenia", value: "hy" },
  { label: "Assami", value: "as" },
  { label: "Avaari", value: "av" },
  { label: "Avestan", value: "ae" },
  { label: "Aymara", value: "ay" },
  { label: "Azerbaidžan", value: "az" },
  { label: "Bambara", value: "bm" },
  { label: "Baškiiri", value: "ba" },
  { label: "Baski", value: "eu" },
  { label: "Valko-Venäjä", value: "be" },
  { label: "Bengali", value: "bn" },
  { label: "Bislama", value: "bi" },
  { label: "Bosnia", value: "bs" },
  { label: "Bretoni", value: "br" },
  { label: "Bulgaria", value: "bg" },
  { label: "Burma", value: "my" },
  { label: "Katalaani, Valencialainen", value: "ca" },
  { label: "Chamorro", value: "ch" },
  { label: "Tšetšeeni", value: "ce" },
  { label: "Chewa", value: "ny" },
  { label: "Kiina", value: "zh" },
  { label: "Tšuvassi", value: "cv" },
  { label: "Cornish", value: "kw" },
  { label: "Korsika", value: "co" },
  { label: "Cree", value: "cr" },
  { label: "Kroatia", value: "hr" },
  { label: "Tšekki", value: "cs" },
  { label: "Tanska", value: "da" },
  { label: "Malediivit", value: "dv" },
  { label: "Hollanti", value: "nl" },
  { label: "Dzongkha", value: "dz" },
  { label: "Englanti", value: "en" },
  { label: "Esperanto", value: "eo" },
  { label: "Viro", value: "et" },
  { label: "Ewe", value: "ee" },
  { label: "Färsaaret", value: "fo" },
  { label: "Fidži", value: "fj" },
  { label: "Suomi", value: "fi" },
  { label: "Ranska", value: "fr" },
  { label: "Länsi-Friisi", value: "fy" },
  { label: "Fulani", value: "ff" },
  { label: "Skotlannin gaeli", value: "gd" },
  { label: "Galicia", value: "gl" },
  { label: "Ganda", value: "lg" },
  { label: "Georgia", value: "ka" },
  { label: "Saksa", value: "de" },
  { label: "Kreikka", value: "el" },
  { label: "Kalaallisut, Grönlanti", value: "kl" },
  { label: "Guarani", value: "gn" },
  { label: "Gujarati", value: "gu" },
  { label: "Haitin", value: "ht" },
  { label: "Hausa", value: "ha" },
  { label: "Hebrea", value: "he" },
  { label: "Herero", value: "hz" },
  { label: "Hindi", value: "hi" },
  { label: "Hiri Motu", value: "ho" },
  { label: "Unkari", value: "hu" },
  { label: "Islanti", value: "is" },
  { label: "Ido", value: "io" },
  { label: "Igbo", value: "ig" },
  { label: "Indonesia", value: "id" },
  { label: "Interlingua", value: "ia" },
  { label: "Interlingue", value: "ie" },
  { label: "Inuktitut", value: "iu" },
  { label: "Inupiaq", value: "ik" },
  { label: "Iiri", value: "ga" },
  { label: "Italia", value: "it" },
  { label: "Japani", value: "ja" },
  { label: "Jaavalainen", value: "jv" },
  { label: "Kannada", value: "kn" },
  { label: "Kanuri", value: "kr" },
  { label: "Kashmiri", value: "ks" },
  { label: "Kazakki", value: "kk" },
  { label: "Keski-Khmer", value: "km" },
  { label: "Kikuyu", value: "ki" },
  { label: "Kinyarwanda", value: "rw" },
  { label: "Kirgiisi", value: "ky" },
  { label: "Komi", value: "kv" },
  { label: "Kongo", value: "kg" },
  { label: "Korea", value: "ko" },
  { label: "Kuanyama, Kwanyama", value: "kj" },
  { label: "Kurdi", value: "ku" },
  { label: "Laos", value: "lo" },
  { label: "Latina", value: "la" },
  { label: "Latvia", value: "lv" },
  { label: "Limburgilainen", value: "li" },
  { label: "Lingala", value: "ln" },
  { label: "Liettua", value: "lt" },
  { label: "Luba-Katanga", value: "lu" },
  { label: "Luxemburg", value: "lb" },
  { label: "Makedonia", value: "mk" },
  { label: "Madagaskar", value: "mg" },
  { label: "Malaiji", value: "ms" },
  { label: "Malajalam", value: "ml" },
  { label: "Malta", value: "mt" },
  { label: "Manx", value: "gv" },
  { label: "Maori", value: "mi" },
  { label: "Marathi", value: "mr" },
  { label: "Marshallese", value: "mh" },
  { label: "Mongolia", value: "mn" },
  { label: "Nauru", value: "na" },
  { label: "Navajo", value: "nv" },
  { label: "Pohjois-Ndebele", value: "nd" },
  { label: "Etelä-Ndebele", value: "nr" },
  { label: "Ndonga", value: "ng" },
  { label: "Nepali", value: "ne" },
  { label: "Norja", value: "no" },
  { label: "Norjan Bokmål", value: "nb" },
  { label: "Norjan Nynorsk", value: "nn" },
  { label: "Nuosu", value: "ii" },
  { label: "Okcitan", value: "oc" },
  { label: "Ojibwa", value: "oj" },
  { label: "Oriya", value: "or" },
  { label: "Oromo", value: "om" },
  { label: "Osseetti", value: "os" },
  { label: "Pali", value: "pi" },
  { label: "Pashto", value: "ps" },
  { label: "Persia", value: "fa" },
  { label: "Puola", value: "pl" },
  { label: "Portugali", value: "pt" },
  { label: "Punjabi", value: "pa" },
  { label: "Quechua", value: "qu" },
  { label: "Romania", value: "ro" },
  { label: "Romania", value: "ro" },
  { label: "Retoromaani", value: "rm" },
  { label: "Ruanda", value: "rn" },
  { label: "Venäjä", value: "ru" },
  { label: "Pohjoissaame", value: "se" },
  { label: "Samoa", value: "sm" },
  { label: "Sango", value: "sg" },
  { label: "Sanskrit", value: "sa" },
  { label: "Sardi", value: "sc" },
  { label: "Serbia", value: "sr" },
  { label: "Shona", value: "sn" },
  { label: "Sindhi", value: "sd" },
  { label: "Sinhala", value: "si" },
  { label: "Slovakia", value: "sk" },
  { label: "Slovenia", value: "sl" },
  { label: "Somalia", value: "so" },
  { label: "Eteläsotho", value: "st" },
  { label: "Espanja", value: "es" },
  { label: "Sunda", value: "su" },
  { label: "Swahili", value: "sw" },
  { label: "Swazi", value: "ss" },
  { label: "Ruotsi", value: "sv" },
  { label: "Tagalog", value: "tl" },
  { label: "Tahiti", value: "ty" },
  { label: "Tadžikki", value: "tg" },
  { label: "Tamili", value: "ta" },
  { label: "Tataari", value: "tt" },
  { label: "Telugu", value: "te" },
  { label: "Thai", value: "th" },
  { label: "Tiibet", value: "bo" },
  { label: "Tigrinja", value: "ti" },
  { label: "Tonga (Tonga-saaret)", value: "to" },
  { label: "Tsonga", value: "ts" },
  { label: "Tswana", value: "tn" },
  { label: "Turkki", value: "tr" },
  { label: "Turkmeeni", value: "tk" },
  { label: "Twi", value: "tw" },
  { label: "Uiguuri", value: "ug" },
  { label: "Ukraina", value: "uk" },
  { label: "Urdu", value: "ur" },
  { label: "Uzbekki", value: "uz" },
  { label: "Venda", value: "ve" },
  { label: "Vietnam", value: "vi" },
  { label: "Volapük", value: "vo" },
  { label: "Valloni", value: "wa" },
  { label: "Wales", value: "cy" },
  { label: "Wolof", value: "wo" },
  { label: "Xhosa", value: "xh" },
  { label: "Jiddiš", value: "yi" },
  { label: "Joruba", value: "yo" },
  { label: "Zhuang, Chuang", value: "za" },
  { label: "Zulu", value: "zu" },
];

const options = [
  "Akaa",
  "Alajärvi",
  "Alavus",
  "Espoo",
  "Forssa",
  "Haapajärvi",
  "Haapavesi",
  "Hamina",
  "Hanko",
  "Harjavalta",
  "Heinola",
  "Helsinki",
  "Huittinen",
  "Hyvinkää",
  "Hämeenlinna",
  "Iisalmi",
  "Ikaalinen",
  "Imatra",
  "Joensuu",
  "Jyväskylä",
  "Jämsä",
  "Järvenpää",
  "Kaarina",
  "Kajaani",
  "Kalajoki",
  "Kangasala",
  "Kankaanpää",
  "Kannus",
  "Karkkila",
  "Kaskinen",
  "Kauhajoki",
  "Kauhava",
  "Kauniainen",
  "Kemi",
  "Kemijärvi",
  "Kerava",
  "Keuruu",
  "Kitee",
  "Kiuruvesi",
  "Kokemäki",
  "Kokkola",
  "Kotka",
  "Kouvola",
  "Kristiinankaupunki",
  "Kuhmo",
  "Kuopio",
  "Kurikka",
  "Kuusamo",
  "Lahti",
  "Laitila",
  "Lappeenranta",
  "Lapua",
  "Lieksa",
  "Lieto",
  "Lohja",
  "Loimaa",
  "Loviisa",
  "Maarianhamina",
  "Mikkeli",
  "Mänttä-Vilppula",
  "Naantali",
  "Nivala",
  "Nokia",
  "Nurmes",
  "Närpiö",
  "Orimattila",
  "Orivesi",
  "Oulainen",
  "Oulu",
  "Outokumpu",
  "Paimio",
  "Parainen",
  "Parkano",
  "Pieksämäki",
  "Pietarsaari",
  "Pori",
  "Porvoo",
  "Pudasjärvi",
  "Pyhäjärvi",
  "Raahe",
  "Raasepori",
  "Raisio",
  "Rauma",
  "Riihimäki",
  "Rovaniemi",
  "Saarijärvi",
  "Salo",
  "Sastamala",
  "Savonlinna",
  "Seinäjoki",
  "Somero",
  "Suonenjoki",
  "Tampere",
  "Tornio",
  "Turku",
  "Ulvila",
  "Uusikaarlepyy",
  "Uusikaupunki",
  "Vaasa",
  "Valkeakoski",
  "Vantaa",
  "Varkaus",
  "Viitasaari",
  "Virrat",
  "Ylivieska",
  "Ylöjärvi",
  "Ähtäri",
  "Äänekoski",
];

function EditGig() {
  const location = useLocation();
  const state = location.state;
  const [selected, setSelected] = useState([]);
  const [wantToCreateAnotherOne, setWantToCreateAnotherOne] = useState(false);
  console.log(state);

  const titleInputRef = useRef();
  const privateTitleInputRef = useRef();
  const customerInputRef = useRef();
  const public_descriptionInputRef = useRef();
  const confidental_desctriptionInputRef = useRef();
  const startInputRef = useRef();
  const endInputRef = useRef();
  const addressInputRef = useRef();
  const cityInputRef = useRef();
  const categoryInputRef = useRef();
  /* const languageInputRef = useRef(); */

  useEffect(() => {
    var tmpLanguages = [];
    var languagesArray = state.language.split(",");

    languagesArray.map((languageValue) => {
      const foundLanguage = languages.find(lang => lang.value === languageValue);
      if (foundLanguage) {
        tmpLanguages.push(foundLanguage);
      }
      return null;
    });
    setSelected(tmpLanguages);
  }, [state.language]);

  function SubmitHandler(e) {
    e.preventDefault();
    const gigData = {
      title: titleInputRef.current.value,
      admin_title: privateTitleInputRef.current.value,
      public_description: public_descriptionInputRef.current.value.replaceAll(
        /\n/g,
        "-n-"
      ),
      confidental_desctription:
        confidental_desctriptionInputRef.current.value.replaceAll(/\n/g, "-n-"),
      start: startInputRef.current.value,
      end: endInputRef.current.value,
      address: addressInputRef.current.value,
      city: cityInputRef.current.value,
      category: categoryInputRef.current.value,
      customer: customerInputRef.current.value,
      /* language: languageInputRef.current.value, */
    };

    Geocode.setLanguage("fi");
    Geocode.setRegion("fi");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();

    Geocode.fromAddress(gigData.address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
      },
      (error) => {
        console.error(error);
      }
    );
    var tmpSelected = [];
    for (var i = 0; i < selected.length; i++) {
      tmpSelected.push(selected[i].value);
    }
    var gigStatus;
    if (state.status === 0) {
      gigStatus = "available";
    } else if (state.status === 1) {
      gigStatus = "reserved";
    } else {
      gigStatus = "done";
    }
    var body = `{
      "title": "${titleInputRef.current.value}",
      "admin_title": "${privateTitleInputRef.current.value}",
      "public_description": "${public_descriptionInputRef.current.value}",
      "confidential_description": "${confidental_desctriptionInputRef.current.value.replaceAll(
        /\n/g,
        "-n-"
      )}",
      "start": "${startInputRef.current.value}",
      "end": "${endInputRef.current.value}",
      "address": "${addressInputRef.current.value}",
      "city": "${cityInputRef.current.value}",
      "coord_latitude": 0,
      "coord_longitude": 0,
      "category": ${categoryInputRef.current.value},
      "language": "${tmpSelected.toString()}",
      "customer": "${customerInputRef.current.value}"
    }`;

    if (wantToCreateAnotherOne) {
      fetch(url + "/admin/gig/add" + ak, {
        method: "POST",
        credentials: "include",
        body: body,
      }).then((response) => {
        if (wantToCreateAnotherOne === true && response.status === 201) {
          window.alert("Keikka luotu onnistuneesti");
        } else if (response.status === 201) {
          window.location = "/koti";
        } else {
          window.alert("Keikan luonti ei onnistunut. Tarkista kentät.");
        }
      });
    } else {
      fetch(url + `/admin/gig/${gigStatus}/${state.id}` + ak, {
        method: "PUT",
        credentials: "include",
        body: body,
      }).then(() => {
        window.location.href = "/koti";
      });
    }
  }

  return (
    <div className="pt-[8%] px-20 relative">
      <img
        src={arrow}
        alt="takaisin"
        className="absolute h-6 px-8 mb-4 cursor-pointer top-16 left-10"
        onClick={() => window.history.back()}
      ></img>
      <form className="m-0 card">
        <div className="grid justify-center w-full grid-cols-3 gap-6 px-8">
          <div className="flex flex-col w-full ">
            <label className="font-bold">Otsikko:</label>
            <input
              className="px-2 inputStyle "
              type="text"
              defaultValue={state.title}
              ref={titleInputRef}
            />
          </div>
          <div className="flex flex-col w-full ">
            <label className="font-bold">Tarkempi otsikko:</label>
            <input
              class="inputStyle px-2 "
              type="text"
              defaultValue={state.admin_title}
              ref={privateTitleInputRef}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Asiakkaan nimi:</label>
            <input
              class="inputStyle px-2"
              type="text"
              defaultValue={state.customer}
              ref={customerInputRef}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold">Alkaa:</label>
            <input
              className="px-2 inputStyle "
              type="datetime-local"
              defaultValue={moment(state.start).format("YYYY-MM-DDTHH:mm")}
              ref={startInputRef}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold">Loppuu:</label>
            <input
              className="px-2 inputStyle "
              type="datetime-local"
              defaultValue={moment(state.end).format("YYYY-MM-DDTHH:mm")}
              ref={endInputRef}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Osoite:</label>
            <input
              className="px-2 inputStyle"
              type="text"
              defaultValue={state.address}
              ref={addressInputRef}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Kaupunki:</label>
            <AutoCompleteInput
              options={options}
              defaultValue={state.city}
              ref={cityInputRef}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Kategoria:</label>
            <select
              className="h-full inputStyle"
              type="number"
              autoComplete="off"
              defaultValue={state.category}
              ref={categoryInputRef}
            >
              <option value="1">Kotisairaanhoito</option>
              <option value="2">Kotihoito</option>
              <option value="3">Henkilökohtainen</option>
              <option value="4">Lastenhoito</option>
              <option value="5">Siivous</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Kielitaito vaatimus:</label>
            <MultiSelect
              options={languages}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
          </div>
        </div>
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/2 px-8 pt-6">
            <label className="font-bold ">Yleistietoa:</label>
            <textarea
              className="px-2 areaStyle"
              type="text"
              defaultValue={state.public_description.replaceAll(/-n-/g, "\n")}
              ref={public_descriptionInputRef}
            />
          </div>
          <div className="flex flex-col w-1/2 px-8 pt-6">
            <label className="font-bold ">Tarkempaa tietoa:</label>
            <textarea
              className="px-2 areaStyle"
              type="text"
              defaultValue={state.confidential_description.replaceAll(
                /-n-/g,
                "\n"
              )}
              ref={confidental_desctriptionInputRef}
            />
          </div>
        </div>
        <div className="flex w-full gap-3 px-8 justify-right">
          <button
            className="h-12 mt-8 text-lg font-bold text-white rounded bg-main w-44 drop-shadow-md"
            onClick={(e) => SubmitHandler(e)}
          >
            Tallenna muutokset
          </button>
          <button
            className={`flex items-center justify-center h-12 mt-8 aspect-[1/1] text-lg font-bold text-white rounded ${
              wantToCreateAnotherOne ? "bg-primary" : "bg-main"
            } drop-shadow-md`}
            onClick={(e) => {
              e.preventDefault();
              setWantToCreateAnotherOne(!wantToCreateAnotherOne);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditGig;
