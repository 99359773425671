import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { url, ak } from "../pages/HomePage";

function EmployeeList() {
  const navigate = useNavigate();

  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    fetch(url + "/admin/user/list" + ak, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setEmployees(
          data.sort((a, b) => {
            if (a.username < b.username) {
              return -1;
            }
            if (a.username > b.username) {
              return 1;
            }
            return 0;
          })
        );
      });
  }, []);

  return (
    <div className="flex justify-center w-full">
      <table className="w-full h-full border-0 card">
        <thead>
          <tr className="text-left border-b-2">
            <th className="p-2 text-black">Nimi</th>
            <th className="p-2 text-black">Käyttäjänimi</th>
            <th className="p-2 text-black">Sähköposti</th>
            <th colSpan={2} className="text-center text-black">
              Hallinnoi
            </th>
          </tr>
        </thead>
        <tbody>
          {employees.map((i) => {
            return (
              <tr className="border-b-2">
                <td
                  className="p-2 py-5 text-black border-black"
                  onClick={() =>
                    navigate("/tyontekija/tiedot", {
                      state: {
                        id: i.id,
                        username: i.username,
                        fullname: i.fullname,
                        gender: i.gender,
                        email: i.email,
                        phone: i.phone,
                        privilege: i.privilege,
                        postnumber: i.postnumber,
                        cities: i.cities,
                        drivers_licence: i.drivers_licence,
                        car: i.car,
                        categories: i.categories,
                        current_gigs: i.current_gigs,
                        previous_gigs: i.previous_gigs,
                        languages: i.languages,
                        account_created: i.account_created,
                        cr_checked: i.cr_checked,
                        cr_check_date: i.cr_check_date,
                        cr_checker: i.cr_checker,
                        only_necessary_msgs: i.only_necessary_msgs,
                      },
                    })
                  }
                >
                  <u className="text-blue-500 ">{i.fullname}</u>
                </td>
                <td className="p-2 py-5 text-black border-black">
                  {i.username}
                </td>
                <td className="p-2 py-5 text-black border-black">{i.email}</td>
                <div className="flex items-center justify-around w-full h-full">
                  <div className="flex justify-between w-full 2xl:w-2/3">
                    <td>
                      <button
                        className={`h-12 px-4 text-base text-white rounded ${
                          i.suspended ? "bg-primary" : "bg-main"
                        } drop-shadow-md`}
                        onClick={() => {
                          if (
                            window.confirm(
                              `Haluatko varmasti jäädyttää käyttäjän ${i.username}`
                            )
                          ) {
                            fetch(url + `/admin/usersuspend/${i.id}/${i.suspended ? 0 : 1}` + ak, {
                              method: "PATCH",
                              credentials: "include",
                            }).then(() =>
                              fetch(url + "/admin/user/list" + ak, {
                                method: "GET",
                                credentials: "include",
                              })
                                .then((response) => {
                                  return response.json();
                                })
                                .then((data) => {
                                  setEmployees(
                                    data.sort((a, b) => {
                                      if (a.username < b.username) {
                                        return -1;
                                      }
                                      if (a.username > b.username) {
                                        return 1;
                                      }
                                      return 0;
                                    })
                                  );
                                })
                            );
                          }
                        }}
                      >
                        Jäädytä
                      </button>
                    </td>
                    <td>
                      <button
                        className="h-12 px-4 text-base text-white rounded bg-main drop-shadow-md"
                        onClick={() =>
                          navigate("/tyontekija/hallinnointi", {
                            state: {
                              id: i.id,
                              username: i.username,
                              fullname: i.fullname,
                              gender: i.gender,
                              email: i.email,
                              phone: i.phone,
                              privilege: i.privilege,
                              postnumber: i.postnumber,
                              cities: i.cities,
                              drivers_licence: i.drivers_licence,
                              car: i.car,
                              categories: i.categories,
                              current_gigs: i.current_gigs,
                              previous_gigs: i.previous_gigs,
                              languages: i.languages,
                              account_created: i.account_created,
                              cr_checked: i.cr_checked,
                              cr_check_date: i.cr_check_date,
                              cr_checker: i.cr_checker,
                              only_necessary_msgs: i.only_necessary_msgs,
                            },
                          })
                        }
                      >
                        Muokkaa
                      </button>
                    </td>
                    <td>
                      <button
                        className="h-12 px-4 text-base text-white rounded bg-main drop-shadow-md"
                        onClick={() => {
                          if (
                            window.confirm(
                              `Haluatko varmasti poistaa käyttäjän ${i.username}`
                            )
                          ) {
                            fetch(url + `/admin/user/delete/${i.id}` + ak, {
                              method: "DELETE",
                              credentials: "include",
                            }).then(() => {
                              window.location = "/tyontekijat";
                            });
                          }
                        }}
                      >
                        Poista
                      </button>
                    </td>
                  </div>
                </div>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default EmployeeList;
