import React, { useState, forwardRef } from "react";

const AutoCompleteInput = forwardRef(
  ({ options, defaultValue = "", ...restProps }, ref) => {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [suggestions, setSuggestions] = useState([]);

    const handleInputChange = (event) => {
      const value = event.target.value;
      setInputValue(value);

      const filteredSuggestions = value
        ? options.filter((option) =>
            option.toLowerCase().includes(value.toLowerCase())
          )
        : [];

      setSuggestions(filteredSuggestions);
    };

    const handleSuggestionClick = (suggestion) => {
      setInputValue(suggestion);
      setSuggestions([]);
    };

    const handleInputBlur = () => {
      // Delay the hiding of suggestions to allow time for suggestion click to trigger
      setTimeout(() => {
        setSuggestions([]);
      }, 200);
    };

    return (
      <div className="relative">
        <input
          className="inputStyle px-2"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          placeholder="Etsi"
          ref={ref}
          {...restProps}
        />
        {inputValue && suggestions.length > 0 && (
          <ul className="suggestions-popup absolute z-10 mt-2 w-full max-h-40 overflow-y-auto bg-white border border-gray-300 rounded shadow-md">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="py-2 px-3 cursor-pointer hover:bg-gray-100"
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
);

export default AutoCompleteInput;
