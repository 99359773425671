import React, { useState, useRef, useEffect } from "react";
import logo from "../assets/Syke.png";
import { url, ak } from "../pages/HomePage";

function LogIn() {
  const [showPass, setShowPass] = useState();
  const [sent, setSent] = useState(true);
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);

  const usernameInputRef = useRef();
  const passwordInputRef = useRef();

  useEffect(() => {
    fetch(url + "/admin/whoami" + ak, {
      method: "GET",
      credentials: "include",
    }).then((response) => {
      if (response.status === 202) {
        window.location = "/koti";
      }
    });
  }, []);

  async function submitHandler() {
    setSent(false);
    var body = `{
      "username": "${usernameInputRef.current.value}",
      "password": "${passwordInputRef.current.value}"
    }`;
    await fetch(url + "/signin/admin" + ak, {
      method: "POST",
      body: body,
      credentials: "include",
    }).then((response) => {
      setSent(true);
      if (response.status === 202) {
        window.location = "/koti";
      } else if(response.status === 400){
        setError(true);
        setError2(false);
      } else {
        setError(false);
        setError2(true);
      }
    });
  }

  return (
    <div className="flex justify-center items-center h-full bg-primary">
      <div className="bg-primary p-5 flex flex-col h-[440px] w-[350px] justify-center items-center">
        <img src={logo} alt="logo" className="w-2/3" />
        <br />
        <input
          placeholder="username"
          className="mb-4 bg-transparent text-base border rounded-sm p-1 focus:outline-none text-white placeholder:text-white"
          onClick={() => setError(false)}
          ref={usernameInputRef}
        ></input>
        <div className="relative">
          <input
            placeholder="password"
            type={showPass ? "text" : "password"}
            className={error || error2 ? "bg-transparent text-base border rounded-sm p-1 focus:outline-none text-white placeholder:text-white" : "mb-8 bg-transparent text-base border rounded-sm p-1 focus:outline-none text-white placeholder:text-white"}
            onClick={() => setError2(false)}
            ref={passwordInputRef}
          ></input>
          <button
            className="absolute right-3 top-1 text-white"
            onClick={() => setShowPass(!showPass)}
          >
            {showPass ? "Peitä" : "Näytä"}
          </button>
        </div>
        {!error ? <></> : <p className="text-red-800 my-1">Käyttäjää ei ole olemassa</p>}
        {!error2 ? <></> : <p className="text-red-800 my-1">Salasana on väärin</p>}
        <button
          className={
            sent
              ? "bg-main hover:bg-zinc-300 text-white font-bold py-2 px-4 border-0 rounded w-2/5"
              : "bg-neutral-500 hover:bg-zinc-300 text-white font-bold py-2 px-4 border-0 rounded w-2/5"
          }
          onClick={() => submitHandler()}
        >
          Kirjaudu
        </button>
      </div>
    </div>
  );
}

export default LogIn;
