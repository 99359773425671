import React, { useEffect, useRef } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "430px",
};

function MapComponent({ centerValue }) {
  console.log(centerValue);
  const mapRef = useRef(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDSB8tU9xV9uUYN3vAk-ZOYgUOZl14EIII",
  });

  useEffect(() => {
    if (isLoaded && !loadError) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: centerValue,
        zoom: 16,
      });

      new window.google.maps.Marker({
        position: centerValue,
        map: map,
      });
    }
  }, [isLoaded, loadError, centerValue]);

  return isLoaded && !loadError ? (
    <div ref={mapRef} style={containerStyle} />
  ) : (
    <div>Error loading map</div>
  );
}

export default React.memo(MapComponent);
