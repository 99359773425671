import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import arrow from "../assets/arrow.png";
import { ak, url } from "../pages/HomePage";

function ReserveGig() {
  const location = useLocation();
  const state = location.state;
  const [searchTerm, setSearchTerm] = useState("");
  const [isClicked, setIsClicked] = useState([false, ""]);
  const [users, setUsers] = useState([]);
  const searchTermInputRef = useRef();

  useEffect(() => {
    fetch(url + "/admin/user/list" + ak, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const tmpUsersList = [];
        for (const key in data) {
          const user = {
            id: key,
            ...data[key],
          };

          tmpUsersList.push(user);
        }
        console.log(tmpUsersList)
        setUsers(tmpUsersList);
      });
  }, []);

  function submitHandler(user) {
    fetch(url + `/admin/gig/reserve/${state.id}/${user}` + ak, {
      method: "POST",
      credentials: "include",
    })
      .then(() => {
        window.location = "/koti"
      })
  }

  ///admin/gig/reserve/:id/:user
  return (
    <div className="w-full h-[90%] flex flex-col items-center justify-center relative">
      <img
        src={arrow}
        alt="takaisin"
        className="h-6 cursor-pointer px-8 mb-4 absolute top-16 left-10"
        onClick={() => window.history.back()}
      ></img>
      <div className="card w-1/3 h-auto">
        <p className="text-lg font-bold">Varaa</p>
        <br />
        <div className="flex flex-row w-full h-1/3">
          <div className="flex flex-col w-1/2 h-36 justify-between">
            <input
              placeholder="Hae"
              className="inputStyle px-2 w-2/3"
              ref={searchTermInputRef}
              onChange={() => {
                setSearchTerm(searchTermInputRef.current.value);
              }}
            ></input>
            <button className="bg-main font-bold text-white text-md rounded h-12 w-32 drop-shadow-md" onClick={() => submitHandler(isClicked[1])}>
              Varaa
            </button>
          </div>
          <div className="h-36 w-1/2 overflow-scroll">
            {users
              .filter((user) => {
                if (
                  user.fullname
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) &&
                  searchTerm !== ""
                ) {
                  return user.fullname;
                } else {
                  return null;
                }
              })
              .map((i) => {
                return (
                  <div onClick={() => setIsClicked([!isClicked[0], i.id])} className={isClicked[0] && i.id === isClicked[1] ? "w-full h-1/3 flex bg-[#cf4e4e] text-text items-center rounded-lg mb-1 px-2" : "w-full h-1/3 flex bg-main text-text items-center rounded-lg mb-1 px-2"}>
                    {i.fullname}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReserveGig;
