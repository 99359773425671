import Logs from "../components/Logs";

function LogsPage() {
  return (
    <div className="w-full h-[90%] flex flex-col items-center pt-32">
      <div className="w-4/5 h-5/6">
        <Logs />
      </div>
    </div>
  );
}

export default LogsPage;
