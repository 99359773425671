import React from "react";
import logo from "../assets/Syke.png"
import { Link } from "react-router-dom";


function NavBar() {
  return (
    <>
      <div className="static flex items-center w-full h-20 bg-primary">
        <div className="flex items-center justify-between w-full px-2 sm:px-10 lg:px-36">
          <Link className="text-base font-bold text-text" to="/koti">
          <img src={logo} alt="logo" className='w-1/5'/>
          </Link>
          <div className="flex flex-row items-center justify-between w-3/5 text-sm text-text sm:w-2/5 lg:w-2/6 ">
            <Link className="hover:text-main focus:text-main" to="/koti">Etusivu</Link>
            <Link className="hover:text-main focus:text-main" to="/luo/keikka">Luo keikka</Link>
            <Link className="hover:text-main focus:text-main" to="/tyontekijat">Työntekijät</Link>
            <Link className="hover:text-main focus:text-main" to="/hallitse">Hallitse</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBar;
