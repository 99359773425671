import React from 'react'
import { useNavigate } from 'react-router-dom'
import AdminList from '../components/AdminList'
import arrow from "../assets/arrow.png"

function AdminPage() {
  const navigate = useNavigate();
  return (
    <div className='pt-[8%] px-20 relative bg-[#f0f9ff]'>
      <img
        src={arrow}
        alt="takaisin"
        className="h-6 cursor-pointer px-8 mb-4 absolute top-16 left-10"
        onClick={() => window.history.back()}
      ></img>
      <div className='flex flex-col'>
        <div className="w-full flex-row">
          <button className='bg-main font-bold text-white text-lg rounded h-12 w-44 drop-shadow-md mr-2' onClick={() => navigate("/lisaa/admin")}>Lisää Admin</button>
    
        </div>
        <br />
        <AdminList />
      </div>
    </div>
  )
}

export default AdminPage