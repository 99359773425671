import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "@emotion/styled";
//version 1.

export const StyleWrapper = styled.div`
  .fc-daygrid-day-frame {
    background-color: white;
  }
  .fc-event-main {
    background-color: #233645;
  }
  .fc-timegrid-slot {
    background-color: white;
  }
  .fc-timegrid-axis-frame {
    background-color: white;
  }
  .fc-event {
    border-color: #233645;
  }
`;
export const url = "https://omasyke.com:8000";
/* export const url = "http://localhost:8080"; */
export const ak = "?ak=iamanadminiamanadminiaasurgeon";

function HomePage() {
  const navigate = useNavigate();
  const [gigs, setGigs] = useState();
  const [doneGigs, setDoneGigs] = useState();
  const [employees, setEmployees] = useState();
  const [showList, setShowList] = useState(0);

  useEffect(() => {
    const tmpGigsList = [];
    const tmpReservedGigsList = [];
    const tmpDoneGigsList = [];
    fetch(url + "/admin/gigs/unabridged" + ak, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        for (const key in data) {
          const gig = {
            id: key,
            ...data[key],
          };

          tmpGigsList.push(gig);
        }
        setGigs(tmpGigsList);
      })
      .then(() => {
        fetch(url + "/admin/gigs/done/unabridged" + ak, {
          method: "GET",
          credentials: "include",
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            for (const key in data) {
              const gig = {
                id: key,
                ...data[key],
              };

              tmpDoneGigsList.push(gig);
            }
            setDoneGigs(tmpDoneGigsList);
          })
          .then(() => {
            fetch(url + "/admin/gigs/reserved/unabridged" + ak, {
              method: "GET",
              credentials: "include",
            })
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                for (const key in data) {
                  const reservedGig = {
                    id: key,
                    ...data[key],
                  };
                  tmpReservedGigsList.push(reservedGig);
                }
                setGigs([
                  ...tmpGigsList,
                  ...tmpReservedGigsList,
                  ...tmpDoneGigsList,
                ]);
              });
          });
      });

    fetch(url + "/admin/user/list" + ak, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setEmployees(
          data.sort((a, b) => {
            if (a.username < b.username) {
              return -1;
            }
            if (a.username > b.username) {
              return 1;
            }
            return 0;
          })
        );
      });
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString("fi-FI");
    const formattedTime = date.toLocaleTimeString("fi-FI").slice(0, -3);

    return `${formattedDate} kello ${formattedTime}`;
  }

  return (
    <div className="pt-[1%] px-10 min-h-full bg-[#f0f9ff]">
      <div className="flex flex-row w-full">
        <select
          className="bg-transparent"
          onChange={(e) => {
            setShowList(Number(e.target.value));
          }}
        >
          <option value={0}>Kalenteri</option>
          <option value={1}>Lista</option>
          <option value={2}>Tehdyt</option>
        </select>
      </div>
      {showList === 1 ? (
        <table className="w-full h-full mt-2 border-0 card">
          <thead>
            <tr className="text-left border-b-2">
              <th className="p-2 text-black">Otsikko</th>
              <th className="p-2 text-black">Alkaa</th>
              <th className="p-2 text-black">Loppuu</th>
              <th className="p-2 text-black">Varattu</th>
              <th colSpan={2} className="text-center text-black">
                Hallinnoi
              </th>
            </tr>
          </thead>
          <tbody>
            {gigs.map((i) => {
              return (
                <tr className="border-b-2" key={i.id}>
                  <td
                    className="p-2 py-5 text-black border-black"
                    onClick={() =>
                      navigate("/keikka/tiedot", {
                        state: {
                          id: i.id,
                          pubd: i.pubd,
                          title: i.title,
                          public_description: i.public_description,
                          confidential_description: i.confidential_description,
                          start: i.start,
                          end: i.end,
                          address: i.address,
                          city: i.city,
                          category: i.category,
                          language: i.language,
                          status: i.status,
                          user: i.user,
                          added_by: i.added_by,
                          report: i.report,
                          admin_title: i.admin_title,
                          customer: i.customer,
                          username: employees.find(employee => employee.id === i.user) ? employees.find(employee => employee.id === i.user).username : undefined,
                        },
                      })
                    }
                  >
                    {i.title}
                  </td>
                  <td className="p-2 py-5 text-black border-black">
                    {formatDate(i.start)}
                  </td>
                  <td className="p-2 py-5 text-black border-black">
                    {formatDate(i.end)}
                  </td>
                  <td className="p-2 py-5 text-black border-black">
                    {i.status === 0 ? "Ei" : "Kyllä"}
                  </td>
                  <td
                    className="py-5 text-center text-black border-black cursor-pointer"
                    onClick={() =>
                      navigate("/keikka/hallinnointi", {
                        state: {
                          title: i.title,
                          admin_title: i.admin_title,
                          public_description: i.public_description,
                          confidential_description: i.confidential_description,
                          customer: i.customer,
                          start: i.start,
                          end: i.end,
                          address: i.address,
                          city: i.city,
                          category: i.category,
                          language: i.language,
                          list: true,
                        },
                      })
                    }
                  >
                    Muokkaa
                  </td>
                  <td
                    className="py-5 text-center text-black border-black"
                    onClick={() => {
                      if (
                        window.confirm(
                          `Haluatko varmasti poistaa keikan ${i.title}`
                        )
                      ) {
                        fetch(url + `/admin/gig/${i.id}` + ak, {
                          method: "DELETE",
                          credentials: "include",
                        }).then(() => {
                          window.location = "/koti";
                        });
                      }
                    }}
                  >
                    Poista
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : showList === 0 ? (
        /*         function(events, className) {
          if (events.status == 0)
          {
           className="bg-red-700 border-orange-300 "
          } */
        <StyleWrapper>
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            initialView="dayGridMonth"
            weekends={true}
            firstDay={1}
            events={gigs}
            eventContent={renderEventContent}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            }}
            eventTimeFormat={{
              hour: "numeric",
              minute: "2-digit",
              meridiem: false,
              hour12: false,
            }}
            height="auto"
            timeZone="Europe/Helsinki"
            /* eventMouseEnter={function (info) {
                      alert("id: " + 
  info.event.id
  )
                      info.el.style.borderColor = 'red';
                  }} */
            eventClick={function (info) {
              console.log(info);
              navigate("/keikka/tiedot", {
                state: {
                  id: info.event._def.publicId,
                  pubd: info.event._def.extendedProps.pubd,
                  title: info.event._def.title,
                  public_description:
                    info.event._def.extendedProps.public_description,
                  confidential_description:
                    info.event._def.extendedProps.confidential_description,
                  address: info.event._def.extendedProps.address,
                  city: info.event._def.extendedProps.city,
                  status: info.event._def.extendedProps.status,
                  category: info.event._def.extendedProps.category,
                  language: info.event._def.extendedProps.language,
                  start: info.event._instance.range.start,
                  end: info.event._instance.range.end,
                  added_by: info.event._def.extendedProps.added_by,
                  report: info.event._def.extendedProps.report,
                  user: info.event._def.extendedProps.user,
                  admin_title: info.event._def.extendedProps.admin_title,
                  customer: info.event._def.extendedProps.customer,
                  username: employees.find(employee => employee.id === info.event._def.extendedProps.user) ? employees.find(employee => employee.id === info.event._def.extendedProps.user).username : undefined,
                  list: false,
                },
              });
              /*   <    alert('Event: ' + info.event.title);
                            alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
                            alert('View: ' + info.view.type);
                            alert("id: " + 
        info.event.id
        )> */
              /* info.el.style.borderColor = 'red'; */
            }}
          />
        </StyleWrapper>
      ) : (
        <table className="w-full h-full mt-2 border-0 card">
          <thead>
            <tr className="text-left border-b-2">
              <th className="p-2 text-black">Otsikko</th>
              <th className="p-2 text-black">Osoite</th>
              <th className="p-2 text-black">Alkaa</th>
              <th className="p-2 text-black">Loppuu</th>
              <th className="p-2 text-black">Tekijä</th>
            </tr>
          </thead>
          <tbody>
            {doneGigs.map((i) => {
              return (
                <tr className="border-b-2" key={i.id}>
                  <td
                    className="p-2 py-5 text-black border-black"
                    onClick={() =>
                      navigate("/keikka/tiedot", {
                        state: {
                          id: i.id,
                          pubd: i.pubd,
                          title: i.title,
                          public_description: i.public_description,
                          confidential_description: i.confidential_description,
                          start: i.start,
                          end: i.end,
                          address: i.address,
                          city: i.city,
                          category: i.category,
                          language: i.language,
                          status: i.status,
                          user: i.user,
                          added_by: i.added_by,
                          report: i.report,
                          admin_title: i.admin_title,
                          customer: i.customer,
                          username: employees.find(employee => employee.id === i.user).username,
                        },
                      })
                    }
                  >
                    {i.title}
                  </td>
                  <td className="p-2 py-5 text-black border-black">
                    {i.address}
                  </td>
                  <td className="p-2 py-5 text-black border-black">
                    {formatDate(i.start)}
                  </td>
                  <td className="p-2 py-5 text-black border-black">
                    {formatDate(i.end)}
                  </td>
                  <td className="p-2 py-5 text-black border-black">
                    {
                      employees.find((employee) => employee.id === i.user)
                        ?.fullname
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

function renderEventContent(eventInfo) {
  return (
    <div
      className={
        eventInfo.event._def.extendedProps.status === 0
          ? "bg-orange w-full rounded-sm p-1"
          : eventInfo.event._def.extendedProps.status === 1
          ? "bg-green w-full rounded-sm p-1"
          : "bg-blue-500 w-full rounded-sm p-1"
      }
    >
      <b className="mr-1 text-text">{eventInfo.timeText}</b>
      <i className="text-text">
        {eventInfo.event._def.extendedProps.admin_title}
      </i>
    </div>
  );
}

export default HomePage;
