import React, { useRef, useState, useEffect } from "react";
/* import Geocode from "react-geocode"; */
import { MultiSelect } from "react-multi-select-component";
import { url, ak } from "../pages/HomePage";
import MapComponent from "../components/Map";
import AutoCompleteInput from "../components/AutoCompleteInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const languages = [
  { label: "Abhaasi", value: "ab" },
  { label: "Afar", value: "aa" },
  { label: "Afrikaans", value: "af" },
  { label: "Akan", value: "ak" },
  { label: "Albania", value: "sq" },
  { label: "Amhara", value: "am" },
  { label: "Arabia", value: "ar" },
  { label: "Aragonialainen", value: "an" },
  { label: "Armenia", value: "hy" },
  { label: "Assami", value: "as" },
  { label: "Avaari", value: "av" },
  { label: "Avestan", value: "ae" },
  { label: "Aymara", value: "ay" },
  { label: "Azerbaidžan", value: "az" },
  { label: "Bambara", value: "bm" },
  { label: "Baškiiri", value: "ba" },
  { label: "Baski", value: "eu" },
  { label: "Valko-Venäjä", value: "be" },
  { label: "Bengali", value: "bn" },
  { label: "Bislama", value: "bi" },
  { label: "Bosnia", value: "bs" },
  { label: "Bretoni", value: "br" },
  { label: "Bulgaria", value: "bg" },
  { label: "Burma", value: "my" },
  { label: "Katalaani, Valencialainen", value: "ca" },
  { label: "Chamorro", value: "ch" },
  { label: "Tšetšeeni", value: "ce" },
  { label: "Chewa", value: "ny" },
  { label: "Kiina", value: "zh" },
  { label: "Tšuvassi", value: "cv" },
  { label: "Cornish", value: "kw" },
  { label: "Korsika", value: "co" },
  { label: "Cree", value: "cr" },
  { label: "Kroatia", value: "hr" },
  { label: "Tšekki", value: "cs" },
  { label: "Tanska", value: "da" },
  { label: "Malediivit", value: "dv" },
  { label: "Hollanti", value: "nl" },
  { label: "Dzongkha", value: "dz" },
  { label: "Englanti", value: "en" },
  { label: "Esperanto", value: "eo" },
  { label: "Viro", value: "et" },
  { label: "Ewe", value: "ee" },
  { label: "Färsaaret", value: "fo" },
  { label: "Fidži", value: "fj" },
  { label: "Suomi", value: "fi" },
  { label: "Ranska", value: "fr" },
  { label: "Länsi-Friisi", value: "fy" },
  { label: "Fulani", value: "ff" },
  { label: "Skotlannin gaeli", value: "gd" },
  { label: "Galicia", value: "gl" },
  { label: "Ganda", value: "lg" },
  { label: "Georgia", value: "ka" },
  { label: "Saksa", value: "de" },
  { label: "Kreikka", value: "el" },
  { label: "Kalaallisut, Grönlanti", value: "kl" },
  { label: "Guarani", value: "gn" },
  { label: "Gujarati", value: "gu" },
  { label: "Haitin", value: "ht" },
  { label: "Hausa", value: "ha" },
  { label: "Hebrea", value: "he" },
  { label: "Herero", value: "hz" },
  { label: "Hindi", value: "hi" },
  { label: "Hiri Motu", value: "ho" },
  { label: "Unkari", value: "hu" },
  { label: "Islanti", value: "is" },
  { label: "Ido", value: "io" },
  { label: "Igbo", value: "ig" },
  { label: "Indonesia", value: "id" },
  { label: "Interlingua", value: "ia" },
  { label: "Interlingue", value: "ie" },
  { label: "Inuktitut", value: "iu" },
  { label: "Inupiaq", value: "ik" },
  { label: "Iiri", value: "ga" },
  { label: "Italia", value: "it" },
  { label: "Japani", value: "ja" },
  { label: "Jaavalainen", value: "jv" },
  { label: "Kannada", value: "kn" },
  { label: "Kanuri", value: "kr" },
  { label: "Kashmiri", value: "ks" },
  { label: "Kazakki", value: "kk" },
  { label: "Keski-Khmer", value: "km" },
  { label: "Kikuyu", value: "ki" },
  { label: "Kinyarwanda", value: "rw" },
  { label: "Kirgiisi", value: "ky" },
  { label: "Komi", value: "kv" },
  { label: "Kongo", value: "kg" },
  { label: "Korea", value: "ko" },
  { label: "Kuanyama, Kwanyama", value: "kj" },
  { label: "Kurdi", value: "ku" },
  { label: "Laos", value: "lo" },
  { label: "Latina", value: "la" },
  { label: "Latvia", value: "lv" },
  { label: "Limburgilainen", value: "li" },
  { label: "Lingala", value: "ln" },
  { label: "Liettua", value: "lt" },
  { label: "Luba-Katanga", value: "lu" },
  { label: "Luxemburg", value: "lb" },
  { label: "Makedonia", value: "mk" },
  { label: "Madagaskar", value: "mg" },
  { label: "Malaiji", value: "ms" },
  { label: "Malajalam", value: "ml" },
  { label: "Malta", value: "mt" },
  { label: "Manx", value: "gv" },
  { label: "Maori", value: "mi" },
  { label: "Marathi", value: "mr" },
  { label: "Marshallese", value: "mh" },
  { label: "Mongolia", value: "mn" },
  { label: "Nauru", value: "na" },
  { label: "Navajo", value: "nv" },
  { label: "Pohjois-Ndebele", value: "nd" },
  { label: "Etelä-Ndebele", value: "nr" },
  { label: "Ndonga", value: "ng" },
  { label: "Nepali", value: "ne" },
  { label: "Norja", value: "no" },
  { label: "Norjan Bokmål", value: "nb" },
  { label: "Norjan Nynorsk", value: "nn" },
  { label: "Nuosu", value: "ii" },
  { label: "Okcitan", value: "oc" },
  { label: "Ojibwa", value: "oj" },
  { label: "Oriya", value: "or" },
  { label: "Oromo", value: "om" },
  { label: "Osseetti", value: "os" },
  { label: "Pali", value: "pi" },
  { label: "Pashto", value: "ps" },
  { label: "Persia", value: "fa" },
  { label: "Puola", value: "pl" },
  { label: "Portugali", value: "pt" },
  { label: "Punjabi", value: "pa" },
  { label: "Quechua", value: "qu" },
  { label: "Romania", value: "ro" },
  { label: "Romania", value: "ro" },
  { label: "Retoromaani", value: "rm" },
  { label: "Ruanda", value: "rn" },
  { label: "Venäjä", value: "ru" },
  { label: "Pohjoissaame", value: "se" },
  { label: "Samoa", value: "sm" },
  { label: "Sango", value: "sg" },
  { label: "Sanskrit", value: "sa" },
  { label: "Sardi", value: "sc" },
  { label: "Serbia", value: "sr" },
  { label: "Shona", value: "sn" },
  { label: "Sindhi", value: "sd" },
  { label: "Sinhala", value: "si" },
  { label: "Slovakia", value: "sk" },
  { label: "Slovenia", value: "sl" },
  { label: "Somalia", value: "so" },
  { label: "Eteläsotho", value: "st" },
  { label: "Espanja", value: "es" },
  { label: "Sunda", value: "su" },
  { label: "Swahili", value: "sw" },
  { label: "Swazi", value: "ss" },
  { label: "Ruotsi", value: "sv" },
  { label: "Tagalog", value: "tl" },
  { label: "Tahiti", value: "ty" },
  { label: "Tadžikki", value: "tg" },
  { label: "Tamili", value: "ta" },
  { label: "Tataari", value: "tt" },
  { label: "Telugu", value: "te" },
  { label: "Thai", value: "th" },
  { label: "Tiibet", value: "bo" },
  { label: "Tigrinja", value: "ti" },
  { label: "Tonga (Tonga-saaret)", value: "to" },
  { label: "Tsonga", value: "ts" },
  { label: "Tswana", value: "tn" },
  { label: "Turkki", value: "tr" },
  { label: "Turkmeeni", value: "tk" },
  { label: "Twi", value: "tw" },
  { label: "Uiguuri", value: "ug" },
  { label: "Ukraina", value: "uk" },
  { label: "Urdu", value: "ur" },
  { label: "Uzbekki", value: "uz" },
  { label: "Venda", value: "ve" },
  { label: "Vietnam", value: "vi" },
  { label: "Volapük", value: "vo" },
  { label: "Valloni", value: "wa" },
  { label: "Wales", value: "cy" },
  { label: "Wolof", value: "wo" },
  { label: "Xhosa", value: "xh" },
  { label: "Jiddiš", value: "yi" },
  { label: "Joruba", value: "yo" },
  { label: "Zhuang, Chuang", value: "za" },
  { label: "Zulu", value: "zu" },
];

const categories = [
  { label: "Kotisairaanhoito", value: "1" },
  { label: "Kotihoito", value: "2" },
  { label: "Henkilökohtainen avustus", value: "3" },
  { label: "Lastenhoito", value: "4" },
  { label: "Siivous", value: "5" },
];

const options = [
  "Akaa",
  "Alajärvi",
  "Alavus",
  "Espoo",
  "Forssa",
  "Haapajärvi",
  "Haapavesi",
  "Hamina",
  "Hanko",
  "Harjavalta",
  "Heinola",
  "Helsinki",
  "Huittinen",
  "Hyvinkää",
  "Hämeenlinna",
  "Iisalmi",
  "Ikaalinen",
  "Imatra",
  "Joensuu",
  "Jyväskylä",
  "Jämsä",
  "Järvenpää",
  "Kaarina",
  "Kajaani",
  "Kalajoki",
  "Kangasala",
  "Kankaanpää",
  "Kannus",
  "Karkkila",
  "Kaskinen",
  "Kauhajoki",
  "Kauhava",
  "Kauniainen",
  "Kemi",
  "Kemijärvi",
  "Kerava",
  "Keuruu",
  "Kitee",
  "Kiuruvesi",
  "Kokemäki",
  "Kokkola",
  "Kotka",
  "Kouvola",
  "Kristiinankaupunki",
  "Kuhmo",
  "Kuopio",
  "Kurikka",
  "Kuusamo",
  "Lahti",
  "Laitila",
  "Lappeenranta",
  "Lapua",
  "Lieksa",
  "Lieto",
  "Lohja",
  "Loimaa",
  "Loviisa",
  "Maarianhamina",
  "Mikkeli",
  "Mänttä-Vilppula",
  "Naantali",
  "Nivala",
  "Nokia",
  "Nurmes",
  "Närpiö",
  "Orimattila",
  "Orivesi",
  "Oulainen",
  "Oulu",
  "Outokumpu",
  "Paimio",
  "Parainen",
  "Parkano",
  "Pieksämäki",
  "Pietarsaari",
  "Pori",
  "Porvoo",
  "Pudasjärvi",
  "Pyhäjärvi",
  "Raahe",
  "Raasepori",
  "Raisio",
  "Rauma",
  "Riihimäki",
  "Rovaniemi",
  "Saarijärvi",
  "Salo",
  "Sastamala",
  "Savonlinna",
  "Seinäjoki",
  "Somero",
  "Suonenjoki",
  "Tampere",
  "Tornio",
  "Turku",
  "Ulvila",
  "Uusikaarlepyy",
  "Uusikaupunki",
  "Vaasa",
  "Valkeakoski",
  "Vantaa",
  "Varkaus",
  "Viitasaari",
  "Virrat",
  "Ylivieska",
  "Ylöjärvi",
  "Ähtäri",
  "Äänekoski",
];

function AddGig() {
  const [selected, setSelected] = useState([]);
  const [autoTime, setAutoTime] = useState();
  const [inputValue, setInputValue] = useState("");
  const [wantToCreateAnotherOne, setWantToCreateAnotherOne] = useState(false);
  const [centerValue, setCenterValue] = useState({
    lat: 60.2115223,
    lng: 25.139447746158936,
  });
  const [categoryValue, setCategoryValue] = useState("1");

  useEffect(() => {
    const timer = setTimeout(async () => {
      try {
        const response = await fetch(
          `https://geocode.maps.co/search?q={${inputValue}}&api_key=661f9774c6db2492763031uogd4e28d`
        );
        const data = await response.json();
        const { lat, lon } = data[0]; // Assuming the response object has 'lat' and 'lng' properties
        setCenterValue({ lat: parseFloat(lat), lng: parseFloat(lon) });
      } catch (error) {
        console.error("Error fetching geocode:", error);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [inputValue]);

  const titleInputRef = useRef();
  const privateTitleInputRef = useRef();
  const public_descriptionInputRef = useRef();
  const confidental_desctriptionInputRef = useRef();
  const startInputRef = useRef();
  const endInputRef = useRef();
  const addressInputRef = useRef();
  const cityInputRef = useRef();
  const postcodeInputRef = useRef();
  const categoryInputRef = useRef();
  const customerInputRef = useRef();
  const phoneInputRef = useRef();
  /*   const languagesInputRef = useRef(); */

  function SubmitHandler(event) {
    event.preventDefault();
    var tmpSelected = [];

    const gigData = {
      title: titleInputRef.current.value,
      admin_title: privateTitleInputRef.current.value,
      public_description: public_descriptionInputRef.current.value.replace(
        /\n/g,
        "-n-"
      ),
      confidental_desctription:
        confidental_desctriptionInputRef.current.value.replace(/\n/g, "-n-"),
      start: startInputRef.current.value,
      end: endInputRef.current.value,
      address: addressInputRef.current.value,
      city: cityInputRef.current.value,
      category: categoryInputRef.current.value,
      customer: customerInputRef.current.value,
      /*  languages: languagesInputRef.current.value + "", */
    };
    for (var i = 0; i < selected.length; i++) {
      tmpSelected.push(selected[i].value);
    }
    console.log(tmpSelected);

    /* Geocode.setApiKey("AIzaSyDE1w1gR2EMf8C7yfo0tc_y4yX3pr0i4aw");
    Geocode.setLanguage("fi");
    Geocode.setRegion("fi");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();

    Geocode.fromAddress(gigData.address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
        coords.push(lat);
        coords.push(lng);
      },
      (error) => {
        console.error(error);
      }
    ); */
    var body = `{
      "title": "${gigData.title}",
      "admin_title": "${gigData.admin_title}",
      "public_description": "${gigData.public_description}",
      "confidential_description": "Puh: ${phoneInputRef.current.value}-n-${
      gigData.confidental_desctription
    }",
      "start": "${gigData.start}",
      "end": "${gigData.end}",
      "address": "${gigData.address}, ${postcodeInputRef.current.value}",
      "city": "${gigData.city}",
      "coord_latitude": ${centerValue.lat},
      "coord_longitude": ${centerValue.lng},
      "category": ${gigData.category},
      "language": "${tmpSelected.toString()}",
      "customer": "${gigData.customer}"
    }`;
    fetch(url + "/admin/gig/add" + ak, {
      method: "POST",
      credentials: "include",
      body: body,
    }).then((response) => {
      if (wantToCreateAnotherOne === true && response.status === 201) {
        window.alert("Keikka luotu onnistuneesti");
      } else if (response.status === 201) {
        window.location = "/koti";
      } else {
        window.alert("Keikan luonti ei onnistunut. Tarkista kentät.");
      }
    });
  }
  return (
    <div className="pt-[4%] px-5 xl:px-10 2xl:px-32 bg-[#f0f9ff]">
      <form className="m-0 card" onSubmit={(event) => SubmitHandler(event)}>
        <div className="grid grid-cols-1 gap-6">
          <div className="grid justify-center w-full grid-cols-3 gap-6">
            <div className="flex flex-col">
              <label className="font-bold ">
                Kategoria<span className="required text-main"> *</span>
              </label>
              <select
                className="h-full inputStyle"
                onChange={() =>
                  setCategoryValue(categoryInputRef.current.value)
                }
                type="number"
                autoComplete="off"
                ref={categoryInputRef}
              >
                <option value="1">Kotisairaanhoito</option>
                <option value="2">Kotihoito</option>
                <option value="3">Henkilökohtainen avustus</option>
                <option value="4">Lastenhoito</option>
                <option value="5">Siivous</option>
              </select>
            </div>
            <div className="flex flex-col w-full ">
              <label className="font-bold">
                Otsikko<span className="required text-main"> *</span>
              </label>
              <input
                defaultValue={
                  categories.find(
                    (category) => category.value === categoryValue
                  ).label
                }
                key={
                  categories.find(
                    (category) => category.value === categoryValue
                  ).label
                }
                class="inputStyle px-2"
                type="text"
                ref={titleInputRef}
              />
            </div>
            <div className="flex flex-col w-full ">
              <label className="font-bold">Tarkempi otsikko</label>
              <input
                class="inputStyle px-2 "
                type="text"
                ref={privateTitleInputRef}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-row gap-3">
              <div className="flex flex-col w-2/3">
                <div className="grid grid-cols-1 gap-6">
                  <div className="grid grid-cols-2 gap-6">
                    <div className="flex flex-col">
                      <label className="font-bold">
                        Asiakkaan nimi
                        <span className="required text-main"> *</span>
                      </label>
                      <input
                        className="px-2 inputStyle"
                        type="text"
                        ref={customerInputRef}
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="font-bold">
                        Puhelinnumero
                        <span className="required text-main"> *</span>
                      </label>
                      <input
                        className="px-2 inputStyle"
                        type="text"
                        ref={phoneInputRef}
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="font-bold">
                        Alkaa<span className="required text-main"> *</span>
                      </label>
                      <input
                        class="inputStyle px-2 "
                        type="datetime-local"
                        ref={startInputRef}
                        onChange={() =>
                          setAutoTime(startInputRef.current.value)
                        }
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="font-bold">
                        Loppuu<span className="required text-main"> *</span>
                      </label>
                      <input
                        class="inputStyle px-2"
                        type="datetime-local"
                        onChange={() => {
                          if (
                            endInputRef.current.value <
                            startInputRef.current.value
                          ) {
                            endInputRef.current.value =
                              startInputRef.current.value;
                          }
                        }}
                        ref={endInputRef}
                        defaultValue={autoTime}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-6">
                    <div className="flex col-span-2 gap-6">
                      <div className="flex flex-col w-2/3">
                        <label className="font-bold ">
                          Osoite<span className="required text-main"> *</span>
                        </label>
                        <input
                          class="inputStyle px-2"
                          type="text"
                          ref={addressInputRef}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col w-1/3">
                        <label className="font-bold whitespace-nowrap">
                          Postinumero
                          <span className="required text-main"> *</span>
                        </label>
                        <input
                          class="inputStyle px-2"
                          type="text"
                          ref={postcodeInputRef}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label className="font-bold ">
                        Kaupunki<span className="required text-main"> *</span>{" "}
                      </label>
                      <AutoCompleteInput options={options} ref={cityInputRef} />
                    </div>
                    <div>
                      <label className="font-bold ">
                        Kielitaito vaatimus
                        <span className="required text-main"> *</span>
                      </label>
                      <MultiSelect
                        options={languages}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-6">
                    <div className="flex flex-col w-full">
                      <label className="font-bold ">Yleistietoa</label>
                      <textarea
                        class="areaStyle px-2"
                        type="text"
                        ref={public_descriptionInputRef}
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <div className="flex flex-row justify-between">
                        <label className="font-bold ">Tarkempaa tietoa</label>
                        <p>Näkyy vain keikan hyväksyttyä</p>
                      </div>
                      <textarea
                        class="areaStyle px-2"
                        type="text"
                        ref={confidental_desctriptionInputRef}
                      />
                    </div>
                    <div className="flex flex-row">
                      <div className="flex mt-3 mr-4">
                        <button
                          className="h-[50px] text-lg font-bold text-white rounded bg-main w-[180px] drop-shadow-md"
                          type="submit"
                        >
                          Luo keikka
                        </button>
                      </div>
                      <div className="flex w-full mt-3">
                        <button
                          className={`flex items-center justify-center h-[50px] w-[50px] text-lg font-bold text-white rounded ${
                            wantToCreateAnotherOne ? "bg-primary" : "bg-main"
                          } drop-shadow-md`}
                          onClick={(e) => {
                            e.preventDefault();
                            setWantToCreateAnotherOne(!wantToCreateAnotherOne);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/3 pl-3">
                <MapComponent centerValue={centerValue} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddGig;
