import React, { useRef, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import arrow from "../assets/arrow.png";
import { url, ak } from "../pages/HomePage";

const options = [
  { label: "Kotisairaanhoito", value: 1 },
  { label: "Kotihoito", value: 2 },
  { label: "Henkilökohtainen avustus", value: 3 },
  { label: "Lastenhoito", value: 4 },
  { label: "Siivous", value: 5 },
  /* { label: "Avustaminen", value: 6 }, */
];

const languages = [
  { label: "Abhaasi", value: "ab" },
  { label: "Afar", value: "aa" },
  { label: "Afrikaans", value: "af" },
  { label: "Akan", value: "ak" },
  { label: "Albania", value: "sq" },
  { label: "Amhara", value: "am" },
  { label: "Arabia", value: "ar" },
  { label: "Aragonialainen", value: "an" },
  { label: "Armenia", value: "hy" },
  { label: "Assami", value: "as" },
  { label: "Avaari", value: "av" },
  { label: "Avestan", value: "ae" },
  { label: "Aymara", value: "ay" },
  { label: "Azerbaidžan", value: "az" },
  { label: "Bambara", value: "bm" },
  { label: "Baškiiri", value: "ba" },
  { label: "Baski", value: "eu" },
  { label: "Valko-Venäjä", value: "be" },
  { label: "Bengali", value: "bn" },
  { label: "Bislama", value: "bi" },
  { label: "Bosnia", value: "bs" },
  { label: "Bretoni", value: "br" },
  { label: "Bulgaria", value: "bg" },
  { label: "Burma", value: "my" },
  { label: "Katalaani, Valencialainen", value: "ca" },
  { label: "Chamorro", value: "ch" },
  { label: "Tšetšeeni", value: "ce" },
  { label: "Chewa", value: "ny" },
  { label: "Kiina", value: "zh" },
  { label: "Tšuvassi", value: "cv" },
  { label: "Cornish", value: "kw" },
  { label: "Korsika", value: "co" },
  { label: "Cree", value: "cr" },
  { label: "Kroatia", value: "hr" },
  { label: "Tšekki", value: "cs" },
  { label: "Tanska", value: "da" },
  { label: "Malediivit", value: "dv" },
  { label: "Hollanti", value: "nl" },
  { label: "Dzongkha", value: "dz" },
  { label: "Englanti", value: "en" },
  { label: "Esperanto", value: "eo" },
  { label: "Viro", value: "et" },
  { label: "Ewe", value: "ee" },
  { label: "Färsaaret", value: "fo" },
  { label: "Fidži", value: "fj" },
  { label: "Suomi", value: "fi" },
  { label: "Ranska", value: "fr" },
  { label: "Länsi-Friisi", value: "fy" },
  { label: "Fulani", value: "ff" },
  { label: "Skotlannin gaeli", value: "gd" },
  { label: "Galicia", value: "gl" },
  { label: "Ganda", value: "lg" },
  { label: "Georgia", value: "ka" },
  { label: "Saksa", value: "de" },
  { label: "Kreikka", value: "el" },
  { label: "Kalaallisut, Grönlanti", value: "kl" },
  { label: "Guarani", value: "gn" },
  { label: "Gujarati", value: "gu" },
  { label: "Haitin", value: "ht" },
  { label: "Hausa", value: "ha" },
  { label: "Hebrea", value: "he" },
  { label: "Herero", value: "hz" },
  { label: "Hindi", value: "hi" },
  { label: "Hiri Motu", value: "ho" },
  { label: "Unkari", value: "hu" },
  { label: "Islanti", value: "is" },
  { label: "Ido", value: "io" },
  { label: "Igbo", value: "ig" },
  { label: "Indonesia", value: "id" },
  { label: "Interlingua", value: "ia" },
  { label: "Interlingue", value: "ie" },
  { label: "Inuktitut", value: "iu" },
  { label: "Inupiaq", value: "ik" },
  { label: "Iiri", value: "ga" },
  { label: "Italia", value: "it" },
  { label: "Japani", value: "ja" },
  { label: "Jaavalainen", value: "jv" },
  { label: "Kannada", value: "kn" },
  { label: "Kanuri", value: "kr" },
  { label: "Kashmiri", value: "ks" },
  { label: "Kazakki", value: "kk" },
  { label: "Keski-Khmer", value: "km" },
  { label: "Kikuyu", value: "ki" },
  { label: "Kinyarwanda", value: "rw" },
  { label: "Kirgiisi", value: "ky" },
  { label: "Komi", value: "kv" },
  { label: "Kongo", value: "kg" },
  { label: "Korea", value: "ko" },
  { label: "Kuanyama, Kwanyama", value: "kj" },
  { label: "Kurdi", value: "ku" },
  { label: "Laos", value: "lo" },
  { label: "Latina", value: "la" },
  { label: "Latvia", value: "lv" },
  { label: "Limburgilainen", value: "li" },
  { label: "Lingala", value: "ln" },
  { label: "Liettua", value: "lt" },
  { label: "Luba-Katanga", value: "lu" },
  { label: "Luxemburg", value: "lb" },
  { label: "Makedonia", value: "mk" },
  { label: "Madagaskar", value: "mg" },
  { label: "Malaiji", value: "ms" },
  { label: "Malajalam", value: "ml" },
  { label: "Malta", value: "mt" },
  { label: "Manx", value: "gv" },
  { label: "Maori", value: "mi" },
  { label: "Marathi", value: "mr" },
  { label: "Marshallese", value: "mh" },
  { label: "Mongolia", value: "mn" },
  { label: "Nauru", value: "na" },
  { label: "Navajo", value: "nv" },
  { label: "Pohjois-Ndebele", value: "nd" },
  { label: "Etelä-Ndebele", value: "nr" },
  { label: "Ndonga", value: "ng" },
  { label: "Nepali", value: "ne" },
  { label: "Norja", value: "no" },
  { label: "Norjan Bokmål", value: "nb" },
  { label: "Norjan Nynorsk", value: "nn" },
  { label: "Nuosu", value: "ii" },
  { label: "Okcitan", value: "oc" },
  { label: "Ojibwa", value: "oj" },
  { label: "Oriya", value: "or" },
  { label: "Oromo", value: "om" },
  { label: "Osseetti", value: "os" },
  { label: "Pali", value: "pi" },
  { label: "Pashto", value: "ps" },
  { label: "Persia", value: "fa" },
  { label: "Puola", value: "pl" },
  { label: "Portugali", value: "pt" },
  { label: "Punjabi", value: "pa" },
  { label: "Quechua", value: "qu" },
  { label: "Romania", value: "ro" },
  { label: "Romania", value: "ro" },
  { label: "Retoromaani", value: "rm" },
  { label: "Ruanda", value: "rn" },
  { label: "Venäjä", value: "ru" },
  { label: "Pohjoissaame", value: "se" },
  { label: "Samoa", value: "sm" },
  { label: "Sango", value: "sg" },
  { label: "Sanskrit", value: "sa" },
  { label: "Sardi", value: "sc" },
  { label: "Serbia", value: "sr" },
  { label: "Shona", value: "sn" },
  { label: "Sindhi", value: "sd" },
  { label: "Sinhala", value: "si" },
  { label: "Slovakia", value: "sk" },
  { label: "Slovenia", value: "sl" },
  { label: "Somalia", value: "so" },
  { label: "Eteläsotho", value: "st" },
  { label: "Espanja", value: "es" },
  { label: "Sunda", value: "su" },
  { label: "Swahili", value: "sw" },
  { label: "Swazi", value: "ss" },
  { label: "Ruotsi", value: "sv" },
  { label: "Tagalog", value: "tl" },
  { label: "Tahiti", value: "ty" },
  { label: "Tadžikki", value: "tg" },
  { label: "Tamili", value: "ta" },
  { label: "Tataari", value: "tt" },
  { label: "Telugu", value: "te" },
  { label: "Thai", value: "th" },
  { label: "Tiibet", value: "bo" },
  { label: "Tigrinja", value: "ti" },
  { label: "Tonga (Tonga-saaret)", value: "to" },
  { label: "Tsonga", value: "ts" },
  { label: "Tswana", value: "tn" },
  { label: "Turkki", value: "tr" },
  { label: "Turkmeeni", value: "tk" },
  { label: "Twi", value: "tw" },
  { label: "Uiguuri", value: "ug" },
  { label: "Ukraina", value: "uk" },
  { label: "Urdu", value: "ur" },
  { label: "Uzbekki", value: "uz" },
  { label: "Venda", value: "ve" },
  { label: "Vietnam", value: "vi" },
  { label: "Volapük", value: "vo" },
  { label: "Valloni", value: "wa" },
  { label: "Wales", value: "cy" },
  { label: "Wolof", value: "wo" },
  { label: "Xhosa", value: "xh" },
  { label: "Jiddiš", value: "yi" },
  { label: "Joruba", value: "yo" },
  { label: "Zhuang, Chuang", value: "za" },
  { label: "Zulu", value: "zu" },
  { label: "Viittomakieli", value: "sign"},
];

function AddUser() {
  const [selected, setSelected] = useState([]);
  const [selected2, setSelected2] = useState([]);
  const [showPass, setShowPass] = useState(false);
  const [showCr, setShowCr] = useState(false);

  /* const usernameInputRef = useRef(); */
  const fullNameInputRef = useRef();
  const emailInputRef = useRef();
  const phoneInputRef = useRef();
  const genderInputRef = useRef();
  /* const privilegeInputRef = useRef(); */
  const passwordInputRef = useRef();
  const postNumberInputRef = useRef();
  const citiesInputRef = useRef();
  const drivingLicenseInputRef = useRef();
  const carInputRef = useRef();
  /* const categoriesInputRef = useRef(); */
  /* const languagesInputRef = useRef(); */
  const crCheckedInputRef = useRef();
  const crCheckDateInputRef = useRef();
  const crCheckerInputRef = useRef();
  const onlyNecassaryMsgsInputRef = useRef();

  function submitHandler(event) {
    event.preventDefault();

    const userData = {
      /* username: usernameInputRef.current.value, */
      fullname: fullNameInputRef.current.value,
      gender: genderInputRef.current.value,
      email: emailInputRef.current.value,
      phone: phoneInputRef.current.value,
      /* privilege: privilegeInputRef.current.value, */
      password: passwordInputRef.current.value,
      postNumber: postNumberInputRef.current.value,
      cities: citiesInputRef.current.value + "",
      driving_license: drivingLicenseInputRef.current.value,
      car: carInputRef.current.value,
      /* categories: categoriesInputRef.current.value, */
      /* languages: languagesInputRef.current.value + "", */
      crChecked: crCheckedInputRef.current.value,
      crCheckDate: crCheckDateInputRef.current.value,
      crChecker: crCheckerInputRef.current.value,
      only_necessary_msgs: onlyNecassaryMsgsInputRef.current.value,
    };
    var tmpSelected = [];
    var tmpSelected2 = [];
    for (var i = 0; i < selected.length; i++) {
      tmpSelected.push(selected[i].value);
    }
    for (var v = 0; v < selected2.length; v++) {
      tmpSelected2.push(selected2[v].value);
    }
    var body = `{
      "username": "${userData.email}",
      "fullname": "${userData.fullname}",
      "gender": "${userData.gender}",
      "email": "${userData.email}",
      "phone": "${userData.phone}",
      "privilege": 1,
      "password": "${userData.password}",
      "postNumber": "${userData.postNumber}",
      "cities": ${JSON.stringify(userData.cities.split(", "))},
      "driving_license": ${userData.driving_license},
      "car": ${userData.car},
      "categories": ${JSON.stringify(tmpSelected)},
      "languages": ${JSON.stringify(tmpSelected2)},
      "only_necessary_msgs": ${userData.only_necessary_msgs},
      "cr_checked": ${userData.crChecked},
      "cr_check_date": ${showCr ? '"' + userData.crCheckDate + '"' : '""'},
      "cr_checker": ${showCr ? '"' + userData.crChecker + '"' : '""'},
      "suspended": ${false}
    }`;

    fetch(url + "/admin/user/create" + ak, {
      method: "POST",
      credentials: "include",
      body: body,
    })
      .then((response) => {
        return response.json();
      })
      .then(() => {
        window.location.href = "/tyontekijat";
      });
  }

  return (
    <div className="pt-[8%] px-20 relative bg-[#f0f9ff]">
      <img
        src={arrow}
        alt="takaisin"
        className="absolute h-6 px-8 mb-4 cursor-pointer top-16 left-10"
        onClick={() => window.history.back()}
      ></img>
      <form className="m-0 card" onSubmit={(event) => submitHandler(event)}>
        <div className="grid justify-center w-full grid-cols-3 gap-6 px-8">
          <div className="flex flex-col w-full ">
            <label className="font-bold">Nimi</label>
            <input
              class="inputStyle px-2 "
              type="text"
              ref={fullNameInputRef}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Sukupuoli</label>
            <input class="inputStyle px-2" type="text" ref={genderInputRef} />
          </div>
          <div className="flex flex-col w-full ">
            <label className="font-bold">Sähköposti</label>
            <input class="inputStyle px-2 " type="text" ref={emailInputRef} />
          </div>
          <div className="flex flex-col w-full">
            <label className="font-bold">Salasana</label>
            <div className="relative">
              <input
                class="inputStyle px-2"
                type={showPass ? "text" : "password"}
                ref={passwordInputRef}
              />
              <button
                className="absolute right-5 top-2"
                onClick={(e) => {
                  setShowPass(!showPass);
                  e.preventDefault();
                }}
              >
                {showPass ? "Peitä" : "Näytä"}
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full ">
            <label className="font-bold">Puhelin</label>
            <input class="inputStyle px-2 " type="text" ref={phoneInputRef} />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Postinumero</label>
            <input
              class="inputStyle px-2"
              type="text"
              ref={postNumberInputRef}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Työskentely kunnat</label>
            <input class="inputStyle px-2" type="text" ref={citiesInputRef} />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Ajokortti</label>
            <select
              className="h-full inputStyle"
              type="number"
              autoComplete="off"
              ref={drivingLicenseInputRef}
            >
              <option value={true}>Kyllä</option>
              <option value={false}>Ei</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Auto</label>
            <select
              className="h-full inputStyle"
              type="number"
              autoComplete="off"
              ref={carInputRef}
            >
              <option value={true}>Kyllä</option>
              <option value={false}>Ei</option>
            </select>
          </div>
          <div>
            <label className="font-bold ">Kategoriat</label>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
          </div>
          <div>
            <label className="font-bold ">Kielet</label>
            <MultiSelect
              options={languages}
              value={selected2}
              onChange={setSelected2}
              labelledBy="Select"
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Rikosrekisteri tarkistettu</label>
            <select
              className="h-full inputStyle"
              type="number"
              autoComplete="off"
              defaultValue={false}
              onChange={() => setShowCr(!showCr)}
              ref={crCheckedInputRef}
            >
              <option value={true}>Kyllä</option>
              <option value={false}>Ei</option>
            </select>
          </div>
          <div className="flex flex-col w-full ">
            <label className="font-bold">Tarkistuksen ajankohta</label>
            <input
              disabled={!showCr}
              class={`inputStyle px-2 ${showCr ? "" : "text-gray-500"}`}
              type="date"
              ref={crCheckDateInputRef}
            />
          </div>
          <div className="flex flex-col w-full ">
            <label className="font-bold">Tarkistaja</label>
            <input
              disabled={!showCr}
              class={`inputStyle px-2 ${showCr ? "" : "text-gray-500"}`}
              defaultValue={"Shila"}
              type="text"
              ref={crCheckerInputRef}
            />
          </div>
          {/* <div className="flex flex-col w-full ">
            <label className="font-bold">Oikeustaso</label>
            <input
              class="inputStyle px-2 "
              type="number"
              ref={privilegeInputRef}
            />
          </div> */}
          <div className="flex flex-col">
            <label className="font-bold ">Vain tarpeelliset ilmoitukset</label>
            <select
              className="inputStyle"
              type="number"
              autoComplete="off"
              ref={onlyNecassaryMsgsInputRef}
            >
              <option value={true}>Kyllä</option>
              <option value={false}>Ei</option>
            </select>
          </div>
          <div className="flex items-end justify-start w-full">
            <button
              className="h-12 text-lg font-bold text-white rounded bg-main w-44 drop-shadow-md"
              type="submit"
            >
              Lisää käyttäjä
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddUser;
