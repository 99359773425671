import { Routes, Route } from "react-router-dom";
import DefaultRoutes from "./components/DefaultRoutes";
import LogIn from "./pages/LogIn";
import PrivacyPolicy from "./pages/PrivacyPolicy";




function App() {
  return (
    <div className="h-screen bg-[#f0f9ff]">
      <Routes>  
        <Route path="/" element={<LogIn />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<DefaultRoutes />} />
      </Routes>
    </div>
  );
}

export default App;
