import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { url, ak } from "../pages/HomePage";


function Logs() {
  const [logs, newLogs] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetch(
      url + "/admin/logs" + ak,
      {
        method: "GET",
        credentials: "include"
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const logMessages = [];
        for (const key in data) {
          const logItem = {
            id: key,
            ...data[key],
          };

          logMessages.push(logItem);
        }
        newLogs([...logMessages])
      })
  }, [])
  function submitHandler() {
    fetch(url + `/signout/admin` + ak, {
      method: "POST",
      credentials: "include",
    })
      .then(() => {
        window.location = "/"
      })
  }
  function removeCookie() {
    document.cookie = "session_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  return (
    <div className='flex flex-row w-full h-full'>
      <div className='w-1/3 h-full p-5 card'>
        <h1 className='text-3xl font-bold'>
          Hallitse
        </h1>
        <br/>
        <button className='bg-main font-bold text-white text-lg rounded h-10 w-40 drop-shadow-md mr-2 mb-2' onClick={() => {submitHandler(); removeCookie()}}>Kirjaudu ulos</button>
        <button className='bg-main font-bold text-white text-lg rounded h-10 w-40 drop-shadow-md mr-2' onClick={() => navigate("/adminit")}>Admin toiminnot</button>
      </div>
      <div className='w-[2px]'/>
      <div className='w-2/3 h-full card p-5 overflow-scroll'>
        <h1 className='text-3xl font-bold mb-2 '>
          Lokitiedot
        </h1>
        {
          logs.map(item => [ <p>{JSON.stringify(item)}</p>, <br /> ])
        }
      </div>
    </div>
  )
}

export default Logs