import React, { useRef, useState } from "react";
import arrow from "../assets/arrow.png";
import { url, ak } from "../pages/HomePage";

function AddAdmin() {
  const [showPass, setShowPass] = useState(false);
  const [buttonState, setButtonState] = useState(false);

  const usernameInputRef = useRef();
  const emailInputRef = useRef();
  const phoneInputRef = useRef();
  const privilegeInputRef = useRef();
  const passwordInputRef = useRef();
  /* const categoriesInputRef = useRef(); */

  async function submitHandler(event) {
    event.preventDefault();

    const userData = {
      username: usernameInputRef.current.value,
      email: emailInputRef.current.value,
      phone: phoneInputRef.current.value,
      privilege: privilegeInputRef.current.value,
      password: passwordInputRef.current.value,
    };
    var body = `{
      "username": "${userData.username}",
      "email": "${userData.email}",
      "phone": "${userData.phone}",
      "privilege": ${userData.privilege},
      "password": "${userData.password}"
    }`;

    await fetch(url + "/admin/admin/create" + ak, {
      method: "POST",
      credentials: "include",
      body: body,
    }).then(() => {
      setButtonState(false);
      window.location = "/adminit";
    });
  }

  return (
    <div className="pt-[8%] px-20 relative">
      <img
        src={arrow}
        alt="takaisin"
        className="h-6 cursor-pointer px-8 mb-4 absolute top-16 left-10"
        onClick={() => window.history.back()}
      ></img>
      <form className="m-0 card">
        <div className="w-full justify-center grid grid-cols-3 gap-6 px-8">
          <div className="w-full flex flex-col ">
            <label className="font-bold">Käyttäjänimi</label>
            <input
              class="inputStyle px-2 "
              type="text"
              ref={usernameInputRef}
            />
          </div>
          <div className="w-full flex flex-col ">
            <label className="font-bold">Sähköposti</label>
            <input class="inputStyle px-2 " type="text" ref={emailInputRef} />
          </div>
          <div className="w-full flex flex-col ">
            <label className="font-bold">Puhelinnumero</label>
            <input class="inputStyle px-2 " type="text" ref={phoneInputRef} />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Oikeustaso</label>
            <select
              className="inputStyle h-full"
              type="number"
              autoComplete="off"
              ref={privilegeInputRef}
            >
              <option value={0}>Omistaja</option>
              <option value={1}>Admin</option>
            </select>
          </div>
          <div className="w-full flex flex-col">
            <label className="font-bold">Salasana</label>
            <div className="relative">
              <input
                class="inputStyle px-2"
                type={showPass ? "text" : "password"}
                ref={passwordInputRef}
              />
              <button
                className="absolute right-5 top-2"
                onClick={(e) => {
                  setShowPass(!showPass);
                  e.preventDefault();
                }}
              >
                {showPass ? "Peitä" : "Näytä"}
              </button>
            </div>
          </div>
          <div className="w-full flex justify-start items-end">
            <button
              className="bg-main font-bold text-white text-lg rounded h-12 w-44 drop-shadow-md"
              disabled={buttonState}
              onClick={(e) => {
                setButtonState(true);
                submitHandler(e);
              }}
            >
              Lisää admin
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddAdmin;
