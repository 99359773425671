import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import arrow from "../assets/arrow.png";
import { url, ak } from "../pages/HomePage";
import './custom-scrollbar.css';


const categories = [
  "Kotisairaanhoito",
  "Kotihoito",
  "Henkilökohtainen avustus",
  "Lastenhoito",
  "Siivous",
];

function EmployeeInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [currentGigs, setCurrentGigs] = useState([]);
  const [previousGigs, setPreviousGigs] = useState([]);

  useEffect(() => {
    // /admin/gigs/user/done/:user-id
    fetch(url + `/admin/gigs/user/reserved/${state.id}` + ak, {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCurrentGigs(data);
      });

    fetch(url + `/admin/gigs/user/done/${state.id}` + ak, {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPreviousGigs(data);
      });
  }, [state.id]);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString("fi-FI");
    const formattedTime = date.toLocaleTimeString("fi-FI").slice(0, -3);

    return `${formattedDate} kello ${formattedTime}`;
  }

  function createCategoriesList() {
    var tmpCategories = [];

    if (state.categories === null) {

    } else {
      state.categories.map((i) => {
        if (i === 1) {
          tmpCategories.push(categories[0]);
        } else if (i === 2) {
          tmpCategories.push(categories[1]);
        } else if (i === 3) {
          tmpCategories.push(categories[2]);
        } else if (i === 4) {
          tmpCategories.push(categories[3]);
        } else if (i === 5) {
          tmpCategories.push(categories[4]);
        } else {
          tmpCategories.push(categories[5]);
        }
        return null;
      });
    }

    return tmpCategories;
  }

  return (
    <div className="flex flex-col w-full h-[90%] items-center justify-center relative">
      <img
        src={arrow}
        alt="takaisin"
        className="absolute h-6 px-8 mb-4 cursor-pointer top-16 left-10"
        onClick={() => window.history.back()}
      ></img>
      <div className="flex flex-row items-center w-3/4">
        <div className="flex flex-col justify-between w-1/3 p-5 h-4/5 card">
          <h1 className="text-3xl font-bold">Tiedot</h1>
          <br />
          <div className="overflow-scroll">
            <p className="text-xl">
              <b>Käyttäjänimi: </b>
              {state.username}
            </p>
            <p className="text-xl">
              <b>Nimi: </b>
              {state.fullname}
            </p>
            <p className="text-xl">
              <b>Sukupuoli: </b>
              {state.gender}
            </p>
            <p className="text-xl">
              <b>Sähköposti: </b>
              {state.email}
            </p>
            <p className="text-xl">
              <b>Puhelin: </b>
              {state.phone}
            </p>
            <p className="text-xl">
              <b>Postinumero: </b>
              {state.postnumber}
            </p>
            <p className="text-xl">
              <b>Alueet missä työskentelee: </b>
              {state.cities.toString().replaceAll(",", ", ")}
            </p>
            <p className="text-xl">
              <b>Ajokortti: </b>
              {state.drivingLicence ? "On" : "Ei ole"}
            </p>
            <p className="text-xl">
              <b>Auto: </b>
              {state.car ? "On" : "Ei ole"}
            </p>
            <p className="text-xl">
              <b>Kategoriat: </b>
              {createCategoriesList().toString().replaceAll(",", ", ")}
            </p>
            <p className="text-xl">
              <b>Kielet: </b>
              {state.languages.toString().replaceAll(",", ", ")}
            </p>
            <p className="text-xl">
              <b>Rikosrekisteri tarkistettu: </b>
              {state.cr_checked ? "Kyllä" : "Ei"}
            </p>
            {state.cr_checked ? (
              <div>
                {" "}
                <p className="text-xl">
                  <b>Tarkistus ajankohta: </b>
                  {state.cr_check_date}
                </p>
                <p className="text-xl">
                  <b>Tarkistaja: </b>
                  {state.cr_checker}
                </p>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <br />
          <button
            className="h-12 text-lg font-bold text-white rounded bg-main w-44 drop-shadow-md"
            onClick={() =>
              navigate("/tyontekija/hallinnointi", {
                state: {
                  id: state.id,
                  username: state.username,
                  fullname: state.fullname,
                  gender: state.gender,
                  email: state.email,
                  phone: state.phone,
                  privilege: state.privilege,
                  postnumber: state.postnumber,
                  cities: state.cities,
                  drivers_licence: state.drivers_licence,
                  car: state.car,
                  categories: state.categories,
                  current_gigs: state.current_gigs,
                  previous_gigs: state.previous_gigs,
                  languages: state.languages,
                  account_created: state.account_created,
                  cr_checked: state.cr_checked,
                  cr_check_date: state.cr_check_date,
                  cr_checker: state.cr_checker,
                  only_necessary_msgs: state.only_necessary_msgs,
                },
              })
            }
          >
            Muokkaa
          </button>
        </div>
        <div className="w-[10px] bg-transparent h-full"></div>
        <div className="flex flex-col w-1/3 p-5 card h-4/5">
          <h1 className="text-3xl font-bold">Tulevat keikat</h1>
          <br />
          <div className="w-full overflow-y-scroll h-96">
            {state.current_gigs !== null ? (
              currentGigs.map((i) => {
                return (
                  <div
                    className="flex flex-col w-full p-1 mb-4 cursor-pointer h-18 bg-card"
                    onClick={() =>
                      navigate("/keikka/tiedot", {
                        state: {
                          id: i.id,
                          pubd: i.pubd,
                          title: i.title,
                          public_description: i.public_description,
                          confidential_description: i.confidential_description,
                          start: i.start,
                          end: i.end,
                          address: i.address,
                          city: i.city,
                          category: i.category,
                          language: i.language,
                          status: i.status,
                          user: i.user,
                          added_by: i.added_by,
                          report: i.report,
                          admin_title: i.admin_title,
                          customer: i.customer,
                        },
                      })
                    }
                  >
                    <div className="flex flex-row justify-between w-full h-1/2">
                      <p className="text-black">
                        <b>{i.title}</b>
                      </p>
                      <p className="text-black">{i.address}</p>
                    </div>
                    <div className="flex flex-row justify-between w-full h-1/2">
                      <b>Alkaa: </b>
                      <p className="w-auto text-black">{formatDate(i.start)}</p>
                      {/* <p className="w-auto text-black">{formatDate(i.end).replace("at", "").replace("AM", "").replace("PM", "")}</p> */}
                    </div>
                  </div>
                );
              })
            ) : (
              <div>Ei keikkoja</div>
            )}
          </div>
        </div>
        <div className="w-[10px] bg-transparent h-full"></div>
        <div className="flex flex-col w-1/3 p-5 card h-4/5">
          <h1 className="text-3xl font-bold">Menneet keikat</h1>
          <br />
          <div className="w-5/6 overflow-y-scroll h-96 scrollbar">
            {state.previous_gigs !== null ? (
              previousGigs.map((i) => {
                return (
                  <div
                    className="flex flex-col w-full p-1 mb-4 h-18 bg-card"
                    onClick={() =>
                      navigate("/keikka/tiedot", {
                        state: {
                          id: i.id,
                          pubd: i.pubd,
                          title: i.title,
                          public_description: i.public_description,
                          confidential_description: i.confidential_description,
                          start: i.start,
                          end: i.end,
                          address: i.address,
                          city: i.city,
                          category: i.category,
                          language: i.language,
                          status: i.status,
                          user: i.user,
                          added_by: i.added_by,
                          report: i.report,
                          admin_title: i.admin_title,
                          customer: i.customer,
                        },
                      })
                    }
                  >
                    <div className="flex flex-row justify-between w-full h-1/2">
                      <p className="text-black">
                        <b>{i.title}</b>
                      </p>
                      <p className="text-black">{i.address}</p>
                    </div>
                    <div className="flex flex-row justify-between w-full h-1/2">
                      <b>Alkoi: </b>
                      <p className="w-auto text-black">{formatDate(i.start)}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>Ei menneitä keikkoja</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeInfo;
