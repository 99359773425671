import NavBar from "./NavBar";
import { Routes, Route } from "react-router-dom";
import HomePage, { ak } from "../pages/HomePage";
import AddGig from "../pages/AddGig";
import LogsPage from "../pages/LogsPage";
import EmployeePage from "../pages/EmployeePage";
import EmployeeInfo from "../pages/EmployeeInfo";
import AddUser from "../pages/AddUser";
import GigInfo from "../pages/GigInfo";
import EditGig from "../pages/EditGig";
import EditUser from "../pages/EditUser";
import AddAdmin from "../pages/AddAdmin";
import EditAdmin from "../pages/EditAdmin";
import ReserveGig from "../pages/ReserveGig";
import AdminPage from "../pages/AdminPage";
import { useEffect, useState } from "react";
import { url } from "../pages/HomePage";
import ReportPage from "../pages/ReportPage";

function DefaultRoutes() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

   useEffect(() => {
    fetch(url + "/admin/whoami" + ak, {
      method: "GET",
      credentials: "include",
    }).then((response) => {
      if (response.status === 202) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  return (
    <div className="h-full">
      {isLoggedIn ? (
        <>
          <NavBar />
          <Routes>
            <Route path="/koti" element={<HomePage />} />
            <Route path="/hallitse" element={<LogsPage />} />
            <Route path="/tyontekijat" element={<EmployeePage />} />
            <Route path="/adminit" element={<AdminPage />} />
            <Route path="/lisaa/tyontekija" element={<AddUser />} />
            <Route path="/tyontekija/tiedot" element={<EmployeeInfo />} />
            <Route path="/tyontekija/hallinnointi" element={<EditUser />} />
            <Route path="/lisaa/admin" element={<AddAdmin />} />
            <Route path="/admin/hallinnointi" element={<EditAdmin />} />
            <Route path="/luo/keikka" element={<AddGig />} />
            <Route path="/keikka/tiedot" element={<GigInfo />} />
            <Route path="/keikka/tiedot/raportti" element={<ReportPage />} />
            <Route path="/keikka/hallinnointi" element={<EditGig />} />
            <Route path="/keikka/varaa" element={<ReserveGig />} />
          </Routes>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full">
            <h1 className="text-4xl">Et ole kirjautunut sisään</h1>
            <br />
            <p className="text-blue-500 cursor-pointer" onClick={() => window.location = "/"}>Kirjautumaan</p>
        </div>
      )}
    </div>
  );
}

export default DefaultRoutes;
