import React from 'react';

function PrivacyPolicy() {
  return (
    <div className=' min-h-full bg-[#f0f9ff] flex justify-center p-8 md:px-0'>
      <div className='w-full max-w-md p-6 bg-white rounded-lg shadow-lg'>
        <h1 className='mb-4 text-2xl font-bold'>Sovelluksen Tietosuojaseloste</h1>
        <p>
          Henkilötietojasi käsitellään, koska olet rekisteröitynyt Omasykeen-sovellukseen. Tämä seloste kertoo, miten henkilötietojasi käsitellään.
        </p>
        <p className='mt-4'>
          Syke Kotipalvelut Oy voi tehdä muutoksia tähän selosteeseen. Suosittelemme, että käyt lukemassa selosteen säännöllisesti. Näin saat tiedon, jos selosteeseen tehdään muutoksia.
        </p>
        <h2 className='mt-6 text-lg font-semibold'>Mitä henkilötietoja tallennamme?</h2>
        <p>
          Sovellus tallentaa kaikki tiedot, jotka annat itsestäsi. Tällaisia tietoja ovat yhteystietosi eli nimi, henkilötunnus, osoite, paikkakunta, puhelinnumero ja sähköpostiosoite (saa tässä lisätä muita tietoja kun pyydämme työntekijältä).
        </p>
        <p>
          Sovellus saa henkilötietosi, kun rekisteröidyt sovellukseen ja teet muutoksia sovelluksessa. Lisäksi sovellus saa tiedot, kun merkintöjä sovelluksen kalenteriin, muistilistoihin. Sovellus säilyttää tietojasi siihen asti, kunnes poistat tilisi.
        </p>
        <h2 className='mt-6 text-lg font-semibold'>Miksi henkilötietojasi tarvitaan?</h2>
        <p>
          Henkilötietojasi tarvitaan, jotta voit käyttää sovelluksen eri ominaisuuksia. Henkilötietosi auttavat varmistamaan, ettei kukaan muu käytä tiliäsi. Kun annat henkilötietosi, sinuun voidaan olla yhteydessä ongelmatilanteissa ja muissa asioissa, jotka liittyvät tiliisi.
        </p>
        <h2 className='mt-6 text-lg font-semibold'>Kuka voi nähdä henkilötietosi?</h2>
        <p>
          Sovellus tallentaa henkilötietosi rekisteriin, jota pitää Syke Kotipalvelut. Tietosi voivat nähdä ainoastaan ne henkilöt, joiden työhön rekisterin käsittely kuuluu. Rekisteriä käsitellään huolellisesti ja tietosi pidetään salassa. Rekisterinpitäjä Syke Kotipalvelut Oy huolehtii, että kukaan ulkopuolinen ei näe tietojasi.
        </p>
        <p>
          Jos sinulla on kysyttävää henkilötietojesi käsittelystä, ole yhteydessä rekisteristä vastaaviin työntekijöihin. Rekisteristä vastaavia työntekijöitä ovat:
        </p>
        <ul className='mt-2 ml-6 list-disc'>
          <li>Toimitusjohtaja Shilan Ahmadi, sähköpostiosoite shilan.ahmadi@sykekotipalvelut.fi, puh: 0401806040</li>
        </ul>
        <h2 className='mt-6 text-lg font-semibold'>Mitä oikeuksia sinulla on henkilötietojesi käsittelyssä?</h2>
        <p>
          Sinulla on oikeus nähdä, mitä tietoja sinusta on tallennettu rekisteriin. Sinulla on oikeus pyytää tietojen korjaamista tai täydentämistä, jos niissä on virheitä. Voit myös pyytää tietojesi poistamista rekisteristä milloin tahansa, jos et halua enää käyttää sovellusta.
        </p>
        <p>
          Jos haluat nähdä, muuttaa tai poistaa tietojasi, ota yhteyttä Shila Ahmadille.
        </p>
        <p>
          Tämä seloste on henkilötietolain pykälien 10 ja 24 sekä EU:n yleisen tietosuoja-asetuksen mukainen.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
