import React from 'react'
import { useNavigate } from 'react-router-dom'
import EmployeeList from '../components/EmployeeList'

function EmployeePage() {

  const navigate = useNavigate();
  return (
    <div className='pt-[5%] px-52 bg-[#f0f9ff]'>
      <div className='flex flex-col'>
        <div className="w-full flex-row">
          <button className='bg-main font-bold text-white text-lg rounded h-12 w-44 drop-shadow-md mr-2' onClick={() => navigate("/lisaa/tyontekija")}>Lisää käyttäjä</button>
        
        </div>
        <br />
        <EmployeeList/>
      </div>
    </div>
  )
}

export default EmployeePage