import React, {useRef} from "react";
import arrow from "../assets/arrow.png";
import { useLocation } from "react-router-dom";
import { url, ak } from "../pages/HomePage";

function EditAdmin() {
  const location = useLocation();
  const state = location.state;
  const usernameInputRef = useRef()
  const emailInputRef = useRef();
  const phoneInputRef = useRef();
  const privilegeInputRef = useRef();


  function submitHandler(event) {
    event.preventDefault();

    const adminData = {
      username: usernameInputRef.current.value,
      email: emailInputRef.current.value,
      phone: phoneInputRef.current.value,
      privilege: privilegeInputRef.current.value,
      /* password: passwordInputRef.current.value, */
    };
   
    var body = `{
      "username": "${adminData.username}",
      "email": "${adminData.email}",
      "phone": "${adminData.phone}",
      "privilege": ${adminData.privilege}
    }`;

    fetch(url + `/admin/admin/edit/${state.id}` + ak, {
      method: "PUT",
      credentials: "include",
      body: body,
    }).then(() => {
      window.location.href = "/adminit";
    });
  }
  return (
    <div className="pt-[8%] px-20 relative">
      <img
        src={arrow}
        alt="takaisin"
        className="h-6 cursor-pointer px-8 mb-4 absolute top-16 left-10"
        onClick={() => window.history.back()}
      ></img>
      <form className="m-0 card">
        <div className="w-full justify-center grid grid-cols-3 gap-6 px-8">
          <div className="w-full flex flex-col ">
            <label className="font-bold text-gray-500">Käyttäjänimi</label>
            <input
              class="inputStyle px-2 text-gray-500"
              type="text"
              defaultValue={state.username}
              ref={usernameInputRef}
              disabled
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Sähköposti</label>
            <input
              class="inputStyle px-2"
              type="text"
              defaultValue={state.email}
              ref={emailInputRef}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Puhelin</label>
            <input
              class="inputStyle px-2"
              type="text"
              defaultValue={state.phone}
              ref={phoneInputRef}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold ">Oikeustaso</label>
            <select
              className="inputStyle"
              type="number"
              autoComplete="off"
              ref={privilegeInputRef}
              defaultValue={state.privilege}
            >
              <option value={0}>Omistaja</option>
              <option value={1}>Admin</option>
            </select>
          </div>
          <div></div>
          <div className="w-full flex items-center justify-end">
            <button className="bg-main font-bold text-white text-lg rounded h-12 w-44 drop-shadow-md"
            onClick={(event) => submitHandler(event)}
            >
              Tallenna muutokset
            </button>
          </div>
        </div>

      </form>
    </div>
  );
}

export default EditAdmin;
